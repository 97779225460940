//(Authentication service)

import axios from 'axios';

const API_URL = 'https://silverdormanager.univerhome.com/api/fth-api/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL, {
        "name":"signin",
        "param":{
                  "email": user.username,
                  "pass": user.password
                }
      },
      {
        headers: {
        'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.data.response.status == '200' && response.data.response.result.typocsscloud) {
          localStorage.setItem('sidorblueSky', JSON.stringify(response.data.response.result));
           
        } 
        return response.data;  
      });
  }

  sendEmailPassCode(email){
    return axios.post(
                      API_URL, 
                      {
                        "name":"sendEmailPassCode",
                        "param":{
                                  "email": email,
                                }
                      },
                      {
                        headers: {
                        'Content-Type': 'application/json'
                        }
                      } 
                      );
  }

  verifyPassCode(code, email){
    return axios.post(
                      API_URL, 
                      {
                        "name":"verifyPassCode",
                        "param":{
                                  "code": code,
                                  "email": email,
                                }
                      },
                      {
                        headers: {
                        'Content-Type': 'application/json'
                        }
                      } 
                      );
  }

  resetPass(code, email, password){
    return axios.post(
                      API_URL, 
                      {
                        "name":"resetPass",
                        "param":{
                                  "code" : code,
                                  "email" : email,
                                  "password" : password
                                }
                      },
                      {
                        headers: {
                        'Content-Type': 'application/json'
                        }
                      } 
                      );
  }

  SignUp(fullname,motivation,email,pass){
    return axios.post(
                      API_URL, 
                      {
                        "name":"SignUp",
                        "param":{
                                  "fullname": fullname,
                                  "motivation": motivation,
                                  "email": email,
                                  "password": pass,
                                }
                      },
                      {
                        headers: {
                        'Content-Type': 'application/json'
                        }
                      } 
                      );
  }
  verifyEmailSignupCode(code, email){
    return axios.post(
                      API_URL, 
                      {
                        "name":"verifyEmailSignupCode",
                        "param":{
                                  "code": code,
                                  "email": email,
                                }
                      },
                      {
                        headers: {
                        'Content-Type': 'application/json'
                        }
                      } 
                      );
  }

  

  

  logout() {
    localStorage.removeItem('sidorblueSky');
  }

}

export default new AuthService();