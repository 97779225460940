<template>
  <div id="app">
    <scrol-top>
      <a class="btn btncss btn-light">
        <b-icon icon="chevron-up" scale="1.5" variant="light"></b-icon>
      </a>
    </scrol-top>
    <div id="nav">
    <top-header></top-header>
    </div>
    <router-view />
    
    <app-footer></app-footer>
    
  </div>
</template>
<script>
import TopHeader from "@/components/TopHeader.vue";
import ScrollTop from "@/components/ScrollTop.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
    };
  },
  components: { 
    "top-header": TopHeader,
    'app-footer': Footer,
    'scrol-top': ScrollTop
  },
  computed: {
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
#app {
  font-family: "Montserrat", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
 
  background-image: linear-gradient(to bottom, #005bff, #9694ff, #bab3ff, #fff, #fff, #fff, #fff, #bab3ff, #9694ff, #005bff);
  
  color: #424242;
  font-size: 1rem;
  position: relative;
  padding-bottom: 150px;
  min-height: 100vh;
  display: block;
}
#nav {
  width: 100%;
  padding: 0px;
  a {
    font-weight: lighter;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #0d0e4f;
    }
  }
}
.btncss {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.55);
    font-size: 18px;
    padding: auto;
    pointer-events: none;
}

</style>
