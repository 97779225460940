<template>
  <b-container fluid class="px-1">
    <b-row class="justify-content-center mx-0 mb-4">
        <h1 style="text-align: center;" id="payment_options">Crypto Payment Options</h1>
    </b-row>
    <b-row class="justify-content-center mx-0 mb-4">
      <b-col xs="12" md="8" class="p-2">
        <p style="padding-left: 10%; padding-right: 10%;">
          Please follow these simple steps:<br><br>
          1. Select a Package, or an Upgrade option if you want to upgrade.<br> 
          2. Send the payment to one of our wallets addresses bellow:<br>
          <b>Bitcoin</b> address (click to select):<br>
          <textarea rows="1" id="btcwall" readonly="readonly" v-model="getAddress[0]" @click.prevent="select('btcwall')" style="max-height:35px;width:300px;word-wrap:break-word; font-size: 10px;">
          </textarea><br>
          <b>Ethereum</b> address (click to select):<br>
          <textarea rows="1" id="ethwall" readonly="readonly" v-model="getAddress[1]" @click.prevent="select('ethwall')" style="max-height:35px;width:300px;word-wrap:break-word;font-size: 10px;">
          </textarea><br>
          <b>Tether USDT Ethereum(ERC-20)</b> address (click to select):<br>
          <textarea rows="1" id="ethercwall" readonly="readonly" v-model="getAddress[1]" @click.prevent="select('ethercwall')" style="max-height:35px;width:300px;word-wrap:break-word;font-size: 10px;">
          </textarea><br>

          <b>Tether USDT Tron(TRC-20)</b> address (click to select):<br>
          <textarea rows="1" id="ethtrcwall" readonly="readonly" v-model="getAddress[2]" @click.prevent="select('ethtrcwall')" style="max-height:35px;width:300px;word-wrap:break-word;font-size: 10px;">
          </textarea><br>

          3. After the payment is done, copy the transaction Hash, then Fill the form bellow to submit your order.<br><br>
          (Note: You will receive an email to your inbox with the download link and your own License key.<br> In case of upgrade you will receive a confirmation of upgrade.)
        </p>
      </b-col>
    </b-row>  
    
    <b-row class="justify-content-center mx-0 p-4 mt-4">
        <h1 id="order-submit" style="text-align: center;">Submit Order</h1>
    </b-row>

      <b-row class="justify-content-center mx-0">
      <b-col xs="12" md="8" class="p-2">
        <b-alert v-if="sendingstatus" variant="info"  show>{{sendingstatus}}</b-alert> 
        <b-form v-show="formshow" @submit.prevent="onSubmit">
          <b-form-group id="input-group-pack" label="Select a Package then send the payment (required):" label-for="input-pack">
              <b-form-select
                id="input-pack"
                v-model="pack"
                :options="getMainprice"
                required
              >
              </b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-hash"
            label="The Payment Transaction hash (required):"
            label-for="input-hash">
            <b-form-input
              id="input-hash"
              v-model="thash"
              type="text"
              placeholder="Enter transaction hash "
              required
            ></b-form-input>
          </b-form-group>
          
          <b-form-group
            id="input-group-1"
            label="Full name (required):"
            label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="name"
              type="text"
              placeholder="Enter your name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Email address (required):"
            label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="email"
              type="email"
              placeholder="Enter your email"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Country (required):"
            label-for="input-3">
            <b-form-input
              id="input-3"
              v-model="country"
              type="text"
              placeholder="Enter the country"
              required
            ></b-form-input>
          </b-form-group>
          
          <vue-recaptcha 
          class="my-4" 
          sitekey="6LdL0p4UAAAAADuEm8alM1PBd0VljyeuuNenRQKm"
          :loadRecaptchaScript="true"
          @verify="onVerify"
          @expired="onExpired"
          >
          </vue-recaptcha>
          <b-button 
          type="submit"
          variant="primary"
          :disabled="loading"
          >
          <span v-show="loading" class="spinner-border spinner-border-sm mr-1"></span>
          <span>Submit Order</span>
          </b-button>
          
          <b-alert class="my-4" v-if="sendingerror" variant="danger"  show>{{sendingerror}}</b-alert> 
        </b-form>
      </b-col>
      </b-row>
  </b-container> 
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha';
  import { mapGetters } from "vuex";
export default {
 
  data() {
    return {
      thash:"",
      pack:"",
      name:"",
      email:"",
      country:"",
      sendingstatus:'',
      sendingerror:'',
      formshow: true,
      loading:false,
      captchaverify:false,
      captchaexpire:false,
    }   
  },
  mounted() {
      this.$store.dispatch("getprice");
      this.$store.dispatch("getcryptoaddr");
      this.scrollto();
  },
  methods:{
    scrollto(){
      document.getElementById('payment_options').scrollIntoView();
    },
    select(id){
      var elemt = document.getElementById(id);
      elemt.select();
    },
    onVerify: function (response) {
      this.sendingerror ='';
      if (response !== '') {
        this.captchaverify = true;
        this.captchaexpire = false;
      }
    },

    onExpired: function () {
      this.captchaexpire = true;
    },
     onSubmit(){
      this.sendingerror ='';
      if(this.captchaverify == true && this.captchaexpire == false){
        this.loading = true;
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            this.loading = false;
            return;
          }

          if (this.name && this.email && this.country && this.pack && this.thash) {
            this.$store.dispatch("btcOrder", {name:this.name, email:this.email, country:this.country, pack:this.pack, thash: this.thash}).then(
              () => {
                this.loading = false;
                this.formshow = false;
                this.sendingstatus = 'Your order was submitted! Our team will process it and deliver ASAP, Thank you!';
              },
              () => {
                this.loading = false;
                this.sendingerror = 'Oops! something went wrong, please try again later!';
              }
            );
          }else{
            this.loading = false;
            this.sendingerror = 'Error! Missed or Invalid Entries!';
          }
        });
      }else{
        this.sendingerror = 'Error! Invalid or expired Captcha!';
      }
     }
  },

  components: {
    "vue-recaptcha": VueRecaptcha,
  },

  computed: {
    ...mapGetters(["getMainprice", "getAddress"]),
  }
};
</script>
<style scoped>


</style>