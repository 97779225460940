<template>
  <div class="w-100 p-3"> 
    <b-row class="d-flex justify-content-around my-4">
      <b-col lg="4" class="my-1">
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search (Rewards History)"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
      </b-col>
      <b-col sm="5" md="4" class="my-1">
          <b-form-group 
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
      </b-col>
      <b-col sm="7" md="4" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <!-- Main table element -->
      <b-table
        class="text-light"
        show-empty
        striped
        dark
        small
        stacked="md"
        ref="tableRef"
        :items="getAffRecords"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      > 
      <template #cell(orderid)="row">
              <div><b>#{{ row.value }}</b></div>
      </template>
      </b-table>
  </b-row>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {
       
    },
    data() {
      return {
        fields: [
          { key: 'orderid', label: 'Order'},
          { key: 'pack', label: 'Package'},
          { key: 'price', label: 'Price'},
          { key: 'rewarded', label: 'Reward'},
          { key: 'status', label: 'Status'},
          { key: 'date', label: 'Date'},
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [  { value: 10, text: "10  per page" },
                { value: 25, text: "25  per page" },
                { value: 50, text: "50  per page" },
                { value: 100, text: "Show a lot" }
               ],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        userId:'' ,
        file:'',
        format: '', 
      }
    },
    computed: {
       ...mapGetters(["getAffRecords"]),

    },

    mounted() {
          
    },
    
    updated(){
      this.totalRows = this.$store.state.affrecords.length;
      this.userId = this.$store.state.currentUId;
    },

    methods: {   
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
    }
  }

</script>

<style scoped>
  /*.resize-tb{
    width: 1200px;
  }*/
</style>