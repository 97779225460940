<template>
    <b-row no-gutters>
    	<b-col xs="12" md="3" class="p-2">
	      <b-card border-variant="primary" text-variant="light" header-tag="header" class="bg-primary text-center">
	      	<template #header>
	      		<div class="row">
	      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">Rewards-Bank</p>
	      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="trophy-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="p" class="text-light numbers">{{getaffBalance}} USD</b-card-text>
	      	<b-button 
                variant="outline-light" 
                size="sm" 
                class="btn-block" 
                @click.prevent="withdraw"
                :disabled="!getpplexist"
                ><span>Withdraw</span>
          </b-button>
	      </b-card>
	    </b-col>
	    <b-col xs="12" md="3" class="p-2">
	      <b-card border-variant="primary" text-variant="light" class="bg-primary text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">This-Month</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="bar-chart-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="p" class="text-light numbers">{{getaffthismonth}}</b-card-text>
	      	<b-card-text text-tag="p" class="mb-0">USD Rewards</b-card-text>
	      </b-card>
	    </b-col>
	    
	    <b-col xs="12" md="3" class="p-2">
	      <b-card border-variant="primary" text-variant="light" class="bg-primary text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">All-Time</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="bar-chart-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="p" class="text-light numbers">{{getaffalltime}}</b-card-text>
	      	<b-card-text text-tag="p" class="mb-0">USD Rewards</b-card-text>
	      </b-card>
	    </b-col>

	    <b-col xs="12" md="3" class="p-2">
	      <b-card border-variant="primary" text-variant="light" class="bg-primary text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">All-Sales</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="bar-chart-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="p" class="text-light numbers">{{getaffallsales}}</b-card-text>
	      	<b-card-text text-tag="p" class="mb-0">Cleared Sales</b-card-text>
	      </b-card>
	    </b-col>
      	 
	    
	</b-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["getaffBalance","getaffpartners","getaffthismonth", "getaffalltime", "getaffallsales","getpplexist"])
  },
  mounted() {
      //this.$store.dispatch("getActorsCount", {router: this.$router});
  },
  methods:{
  	withdraw(){
  		this.$store.dispatch("withdraw", {router: this.$router, affid: this.$store.state.affId});
  	}
  }

};

</script>

<style scoped>
  .widget-css {
  	background: #ddff87;
  	border-radius: 3px;
  }

  .icon-color {
  	color: #F1F1F1;
  	opacity: 0.1;
  }
  header.card-header {
  	padding: 4px;
  }

  div.card-body {
  	padding: 8px;
  }
</style>