<template>
	<b-container fluid class="px-1"> 
      <b-row class="justify-content-center mx-auto my-4">
        <b-icon icon="x-circle" scale="5" variant="danger"></b-icon>
      </b-row>    
	    <b-row class="justify-content-center mx-0 mt-4">
    	   <h4 style="text-align: center;padding-left: 10%; padding-right: 10%;padding-top: 20px;">Sorry! Payment Failed. Please check if there is any problem with your bank card or Paypal, then try again!<br>
         Or Contact us for help!</h4>
	    </b-row>
      <b-row class="justify-content-center mx-0">
        <h1 style="text-align: center;padding-left: 10%; padding-right: 10%;">Thank You!</h1>
      </b-row>
	</b-container> 
</template>

<script>
export default {
 
  data() {
    return {
      visible: false
    };
  },

  mounted(){
    this.$analytics.fbq('track', 'SuccessPayment', {currency: "USD", value: 35.00});
  }
};
</script>
<style scoped>


</style>