<template>
	<b-container fluid class="px-1">    
    <b-row class="justify-content-center mx-0 mb-4">
        <h1 style="text-align: center;">Package Upgrade</h1><br>
    </b-row>
    <b-row class="justify-content-center mx-0 mb-4">
      <b-col xs="12" md="8" class="p-2">
        <p>
          To upgrade:<br><br>
          1. Fill the Upgrade Order form bellow to pay normally, Or click "Upgrade With Cryptocurrency".<br>
          2. You will be upgraded after payment and notified by email.
        </p>
      </b-col>
    </b-row>
    <b-row class="justify-content-center mt-4 mb-4 p-2" no-gutters> 
       <b-button 
            class="p-2" 
            size="lg"
            rounded
            id="crypto" 
            variant="warning"
            @click.prevent="crypto"
            >Upgrade With Cryptocurrency</b-button>
    </b-row> 
    <b-row class="justify-content-center mx-0 p-4 mt-4">
        <h1 id="review-submit" style="text-align: center;">Upgrade Order</h1>
      </b-row>

      <b-row class="justify-content-center mx-0">
      <b-col xs="12" md="8" class="p-2"> 
        <b-form>
          <b-form-group
            id="input-group-2"
            label="License Email (required):"
            label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="email"
              type="email"
              placeholder="Enter your current license email"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-pack" label="Upgrade Option (required):" label-for="input-pack">
              <b-form-select
                id="input-pack"
                v-model="upId"
                :options="getUpgrade"
                required
              ></b-form-select>
          </b-form-group>
          <vue-recaptcha 
          class="my-4" 
          sitekey="6LdL0p4UAAAAADuEm8alM1PBd0VljyeuuNenRQKm"
          :loadRecaptchaScript="true"
          @verify="onVerify"
          @expired="onExpired"
          >
          </vue-recaptcha>
          <b-button 
          variant="primary"
          :disabled="loading"
          @click.prevent="ordernow(upId)"
          >
          <span v-show="loading" class="spinner-border spinner-border-sm mr-1"></span>
          <span>Upgrade Now</span>
          </b-button>
          
          <b-alert class="my-4" v-if="sendingerror" variant="danger"  show>{{sendingerror}}</b-alert> 
        </b-form>
      </b-col>
      </b-row>
	</b-container> 
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha';
  import { mapGetters } from "vuex";

export default {
 
  data() {
    return {
      upId:1255,
      email:"",
      sendingerror:'',
      loading:false,
      captchaverify:false,
      captchaexpire:false
    }   
  },
  mounted() {
      this.$store.dispatch("getprice");
  },

  methods:{
    crypto(){
      this.$router.push("/crypto-payment");
    },
    onVerify: function (response){
      this.sendingerror ='';
      if (response !== '') {
        this.captchaverify = true;
        this.captchaexpire = false;
      }
    },

    onExpired: function () {
      this.captchaexpire = true;
    },

    ordernow(id){
      this.sendingerror ='';
      if(this.captchaverify == true && this.captchaexpire == false){
        this.loading = true;
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            this.loading = false;
            return;
          }

          if (this.email && id) {
            this.$store.dispatch("ordernow", {product:id, referrer: ''});
          }else{
            this.sendingerror = 'Unexpected error!';
          }
        });
      }else{
        this.sendingerror = 'Invalid or expired Captcha!';
      }
     }
  },

  components: { 
    "vue-recaptcha": VueRecaptcha,
  },

  computed: {
    ...mapGetters(["getUpgrade"]),
  }

};
</script>
<style scoped>


</style>