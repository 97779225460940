<template>
 <b-container>
      <b-row class="justify-content-center">
        <b-col xs="12" md="12" class="px-3 pt-3 text-secondary">
          <h4>Welcome to the Silverdor Affiliate Program </h4>
          <p>You get a 15% commission from each purchase of a Silverdor Package through your affiliate link, it's calculated based on the package price (e.g: Medium Package commission $39.95 x 15% = $6.00). We will increase the commission rate to 20% after reaching 50 cleared sales, then 25% when you reach 200 cleared sales. 
          (Note: we provide special partnership commission rates when your results are outstanding, 1000+ sales). Let's get started</p>
          </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col xs="12" md="6" class="p-3">
          <div class="page" v-if="getSpinner">
            <b-spinner class="spinner" :variant="'primary'" :key="'primary'"></b-spinner>
          </div>
          <b-card class="bg-dark text-center text-light" title="SignUp Request">
            <b-alert v-if="message[0]" :variant="message[2]"  show>{{message[1]}}</b-alert>
            <b-alert v-if="getSignUpeState[0]" :variant="getSignUpeState[2]"  show>{{getSignUpeState[1]}}</b-alert>
            <b-alert v-if="typeerr" variant="danger"  show dismissible>{{ typeerr }}</b-alert>

            <b-form data-vv-scope="signupform" v-show="signupformshow">
              <b-form-group>
                <b-form-input
                @input="reseterr"
                size="sm"
                placeholder="Full Name"
                v-model="fullname"
                v-validate="'required|max:120'"
                type="text"
                data-vv-as="Full Name"
                name="fullname"
                /> 
              </b-form-group>
              <b-form-group>
                <b-form-textarea
                @input="reseterr"
                size="sm"
                v-validate="'required|min:20|max:250'"
                v-model="motivation"
                data-vv-as="Promotion Description"
                name="motivation"
                placeholder="Please describe how do you plan to promote our software?"
                rows="3"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group>
                <b-form-input
                @input="reseterr"
                size="sm"
                placeholder="Email"
                v-model="email"
                v-validate="'required|email'"
                type="email"
                data-vv-as="Email"
                name="email"
                /> 
              </b-form-group>
              <b-form-group>
                <b-form-input
                @input="reseterr"
                size="sm"
                v-validate="'required|min:10|max:16'"
                v-model = "newpassword"
                name="new_password" 
                type="password" 
                placeholder="Enter Password" 
                ref="newpassword"
                data-vv-as="Password" 
                autocomplete="off"
                />
               
              </b-form-group>
              <b-form-group>
                <b-form-input
                @input="reseterr"
                size="sm"
                v-validate="'required|confirmed:newpassword'"
                v-model = "confirmpassword"
                name="password_confirmation" 
                type="password" 
                placeholder="Re-enter Password" 
                data-vv-as="Password Confirmation" 
                autocomplete="off"
                />
              </b-form-group>
              <!-- ERRORS -->
              <div class="alert alert-danger" v-show="errors.any('signupform')">
                <div class="fontsize" v-if="errors.has('signupform.email')">
                  &#10004; {{ errors.first('signupform.email') }}
                </div>
                <div class="fontsize" v-if="errors.has('signupform.fullname')">
                  &#10004; {{ errors.first('signupform.fullname') }}
                </div>
                <div class="fontsize" v-if="errors.has('signupform.motivation')">
                  &#10004; {{ errors.first('signupform.motivation') }}
                </div>
                <div class="fontsize" v-if="errors.has('signupform.new_password')">
                  &#10004; {{ errors.first('signupform.new_password') }}
                </div>
                <div class="fontsize" v-if="errors.has('signupform.password_confirmation')">
                  &#10004; {{ errors.first('signupform.password_confirmation') }}
                </div>
              </div>
              <b-form-group>
                <b-button 
                variant="primary" 
                size="sm" 
                class="btn-block" 
                :disabled="loading"
                @click.prevent="SignUp"
                >
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>SignUp</span>
                </b-button>
                
              </b-form-group>
            </b-form>

            <b-form data-vv-scope="submitcode" v-show="signcodeformshow">
              <b-form-group>
                <b-form-input
                @input="reseterr"
                size="sm"
                placeholder="Enter verification code (check email)"
                v-model="code"
                v-validate="'required'"
                type="text"
                data-vv-as="code"
                name="code"
                /> 
              </b-form-group>

              <!-- ERRORS -->
              <div class="alert alert-danger" v-show="errors.any('submitcode')">
                <div class="fontsize" v-if="errors.has('submitcode.code')">
                  &#10004; {{ errors.first('submitcode.code') }}
                </div>
              </div>
             
              <b-form-group>
                <b-button 
                variant="primary" 
                size="sm" 
                class="btn-block" 
                :disabled="submitloading"
                @click.prevent="verifyEmailSignupCode"
                >
                  <span v-show="submitloading" class="spinner-border spinner-border-sm"></span>
                  <span>Submit Code</span>
                </b-button>
              </b-form-group>
            </b-form>
            <b-button 
                size="sm"
                variant="link"
                class="btn-block "
                @click.prevent="signIn"
                >Already Signed-Up? Signin</b-button>
            <b-button 
                size='sm'
                variant="link"
                ><a href="https://silverdor.com/Support" target="_blank">Contact us</a>
            </b-button>
          </b-card>
      </b-col>

      <b-col xs="12" md="6"  class="p-3 text-light">
          <b-card header-tag="header" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0">
              <template #header>
                <p class="mb-0 text-secondary">Top 5 Affiliates (Worldwide)</p>
              </template>
              <!--card-body-->
              <b-table
              class="text-light"
              show-empty
              striped
              dark
              small
              stacked="md"
              ref="tableRef"
              :items="getTopFive"
              :fields="leaderfields" 
            > 
            <template #cell(affid)="row">
              <div class="w-100">
                  <svg v-if="row.index == 0" width="30px" height="30px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione" preserveAspectRatio="xMidYMid meet">
                  <path fill="#428bc1" d="M46.1 2L34.8 18.9h5.6L53 2z">
                  </path>
                  <path fill="#e8e8e8" d="M40.4 2L29.2 18.9h5.6L46.1 2z">
                  </path>
                  <path fill="#ed4c5c" d="M34.8 2L23.6 18.9h5.6L40.4 2z">
                  </path>
                  <g fill="#ffce31">
                  <circle cx="32" cy="41.4" r="20.6">
                  </circle>
                  <path d="M21.7 15.1c-.5 0-.9.4-.9.9v8.4c0 .5.4.9.9.9h20.6c.5 0 .9-.4.9-.9V16c0-.5-.4-.9-.9-.9H21.7m19.7 6.6c0 .5-.4.9-.9.9H23.6c-.5 0-.9-.4-.9-.9v-3.8c0-.5.4-.9.9-.9h16.9c.5 0 .9.4.9.9v3.8">
                  </path>
                  </g>
                  <path d="M14.3 43.8c0-11.2 8.6-20.3 19.6-21.1c-.5 0-1.1-.1-1.6-.1c-10.5 0-19 8.5-19 19.1c0 3.1.8 6.1 2.1 8.7c-.7-2.1-1.1-4.3-1.1-6.6" opacity=".5" fill="#89664c">
                  </path>
                  <path d="M39.9 57.3C49.2 51.1 52 39 46.6 29.6l.9 1.2c5.8 8.6 3.4 20.3-5.3 26.2c-2.6 1.8-5.5 2.8-8.4 3.1c2.2-.6 4.2-1.5 6.1-2.8" opacity=".33" fill="#ffffff">
                  </path>
                  <path fill="#ed4c5c" d="M23.6 18.9h5.6L17.9 2H11z">
                  </path>
                  <path fill="#e8e8e8" d="M29.2 18.9h5.6L23.6 2h-5.7z">
                  </path>
                  <path opacity=".5" fill="#3e4347" d="M32.9 4.8L30.1 9l6.6 9.9h3.7l1-1.4z">
                  </path>
                  <path fill="#428bc1" d="M34.8 18.9h5.6L29.2 2h-5.6z">
                  </path>
                  <g fill="#89664c">
                  <path d="M26.8 35.2v1c1 0 1.9-.2 2.8-.6v-1.4c-.8.6-1.7.9-2.8 1" opacity=".5">
                  </path>
                  <path opacity=".5" d="M33.4 28.7h.9v21.6h-.9z">
                  </path>
                  <path opacity=".5" d="M37.2 51.2V54H26.8v1h11.3v-3.8z">
                  </path>
                  </g>
                  <g fill="#ffffff">
                  <path opacity=".33" d="M34.3 50.3h2.8v.9h-2.8z">
                  </path>
                  <path d="M30.6 28.7h2.8v-.9h-3.8c0 2.1-1.7 3.8-3.8 3.8v3.7c.3 0 .6 0 .9-.1v-2.8c2.2.1 3.9-1.6 3.9-3.7" opacity=".33">
                  </path>
                  <path d="M30.6 51.2v-16c-.3.2-.6.3-.9.5v14.6h-3.8V54h.9v-2.8h3.8" opacity=".33">
                  </path>
                  </g>
                  </svg>
                
               <svg v-if="row.index == 1" width="30px" height="30px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione" preserveAspectRatio="xMidYMid meet">
                <path fill="#428bc1" d="M46.1 2L34.8 18.9h5.6L53 2z">
                </path>
                <path fill="#e8e8e8" d="M40.4 2L29.2 18.9h5.6L46.1 2z">
                </path>
                <path fill="#ed4c5c" d="M34.8 2L23.6 18.9h5.6L40.4 2z">
                </path>
                <g fill="#b2c1c0">
                <circle cx="32" cy="41.4" r="20.6">
                </circle>
                <path d="M21.7 15.1c-.5 0-.9.4-.9.9v8.4c0 .5.4.9.9.9h20.6c.5 0 .9-.4.9-.9V16c0-.5-.4-.9-.9-.9H21.7m19.7 6.6c0 .5-.4.9-.9.9H23.6c-.5 0-.9-.4-.9-.9v-3.8c0-.5.4-.9.9-.9h16.9c.5 0 .9.4.9.9v3.8">
                </path>
                </g>
                <path d="M14.3 43.8c0-11.2 8.6-20.3 19.6-21.1c-.5 0-1.1-.1-1.6-.1c-10.5 0-19 8.5-19 19.1c0 3.1.8 6.1 2.1 8.7c-.7-2.1-1.1-4.3-1.1-6.6" opacity=".5" fill="#3e4347">
                </path>
                <path d="M39.9 57.3C49.2 51.1 52 39 46.6 29.6c.3.4.6.8.9 1.3c5.8 8.6 3.4 20.3-5.3 26.2c-2.6 1.8-5.5 2.8-8.4 3.1c2.2-.7 4.2-1.6 6.1-2.9" opacity=".33" fill="#ffffff">
                </path>
                <path fill="#ed4c5c" d="M23.6 18.9h5.6L17.9 2H11z">
                </path>
                <path fill="#e8e8e8" d="M29.2 18.9h5.6L23.6 2h-5.7z">
                </path>
                <path opacity=".5" fill="#3e4347" d="M32.9 4.8L30.1 9l6.6 9.9h3.7l1-1.4z">
                </path>
                <path fill="#428bc1" d="M34.8 18.9h5.6L29.2 2h-5.6z">
                </path>
                <g fill="#3e4347">
                <path d="M31.5 31.5c-2.1 0-3.8 1.7-3.8 3.7H25c0 .3-.1.6-.1.9h3.8c0-2.1 1.7-3.8 3.8-3.8c.8 0 1.5.3 2.1.7c-.7-.8-1.8-1.5-3.1-1.5" opacity=".5">
                </path>
                <path opacity=".5" d="M39 54H25v1h15v-3.8h-1z">
                </path>
                <path d="M31.5 42.8c-2.1 0-3.8 1.7-3.8 3.8v3.8h.9v-2.8c0-2.1 1.7-3.8 3.8-3.8c4.1 0 7.5-3.4 7.5-7.5c0-2.3-1-4.4-2.7-5.8c1.1 1.3 1.8 3 1.8 4.8c0 4.1-3.3 7.5-7.5 7.5" opacity=".5">
                </path>
                </g>
                <g fill="#ffffff">
                <path opacity=".33" d="M28.7 50.3H39v.9H28.7z">
                </path>
                <path d="M32.5 40c2.1 0 3.8-1.7 3.8-3.8c0-1.3-.6-2.4-1.6-3.1c.4.6.7 1.3.7 2.1c0 2.1-1.7 3.8-3.8 3.8c-4.1 0-7.5 3.4-7.5 7.5V54h.9v-6.6c0-4.1 3.3-7.4 7.5-7.4" opacity=".33">
                </path>
                <path d="M32.5 28.7c1.8 0 3.5.7 4.8 1.8c-1.4-1.6-3.4-2.7-5.8-2.7c-4.1 0-7.5 3.4-7.5 7.5h1c.5-3.7 3.6-6.6 7.5-6.6" opacity=".33">
                </path>
                </g>
                </svg>
                <svg v-if="row.index == 2" width="30px" height="30px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione" preserveAspectRatio="xMidYMid meet">
                  <path fill="#428bc1" d="M46.1 2L34.8 18.9h5.6L53 2z">
                  </path>
                  <path fill="#e8e8e8" d="M40.4 2L29.2 18.9h5.6L46.1 2z">
                  </path>
                  <path fill="#ed4c5c" d="M34.8 2L23.6 18.9h5.6L40.4 2z">
                  </path>
                  <g fill="#d3976e">
                  <circle cx="32" cy="41.4" r="20.6">
                  </circle>
                  <path d="M21.7 15.1c-.5 0-.9.4-.9.9v8.4c0 .5.4.9.9.9h20.6c.5 0 .9-.4.9-.9V16c0-.5-.4-.9-.9-.9H21.7m19.7 6.6c0 .5-.4.9-.9.9H23.6c-.5 0-.9-.4-.9-.9v-3.8c0-.5.4-.9.9-.9h16.9c.5 0 .9.4.9.9v3.8">
                  </path>
                  </g>
                  <path d="M14.3 43.8c0-11.2 8.6-20.3 19.6-21.1c-.5 0-1.1-.1-1.6-.1c-10.5 0-19 8.5-19 19.1c0 3.1.8 6.1 2.1 8.7c-.7-2.1-1.1-4.3-1.1-6.6" opacity=".5" fill="#3e4347">
                  </path>
                  <path d="M39.9 57.3C49.2 51.1 52 39 46.6 29.6l.9 1.2c5.8 8.6 3.4 20.3-5.3 26.2c-2.6 1.8-5.5 2.8-8.4 3.1c2.2-.6 4.2-1.5 6.1-2.8" opacity=".33" fill="#ffffff">
                  </path>
                  <path fill="#ed4c5c" d="M23.6 18.9h5.6L17.9 2H11z">
                  </path>
                  <path fill="#e8e8e8" d="M29.2 18.9h5.6L23.6 2h-5.7z">
                  </path>
                  <path opacity=".5" fill="#3e4347" d="M32.9 4.8L30.1 9l6.6 9.9h3.7l1-1.4z">
                  </path>
                  <path fill="#428bc1" d="M34.8 18.9h5.6L29.2 2h-5.6z">
                  </path>
                  <g fill="#3e4347">
                  <path d="M31.5 31.5c-2.1 0-3.8 1.7-3.8 3.7H25c0 .3-.1.6-.1.9h3.8c0-2.1 1.7-3.8 3.8-3.8c.8 0 1.5.3 2.1.7c-.7-.8-1.8-1.5-3.1-1.5" opacity=".5">
                  </path>
                  <path d="M37.4 41.8c1.6-1.4 2.6-3.4 2.6-5.6c0-2.3-1-4.4-2.7-5.8c1.1 1.3 1.8 3 1.8 4.8c0 2.2-1 4.3-2.6 5.6c1.6 1.4 2.6 3.4 2.6 5.6c0 4.1-3.4 7.5-7.5 7.5c-1.8 0-3.5-.7-4.8-1.8c1.4 1.6 3.4 2.7 5.8 2.7c4.1 0 7.5-3.4 7.5-7.5c-.1-2.1-1.1-4.1-2.7-5.5" opacity=".5">
                  </path>
                  </g>
                  <g fill="#ffffff">
                  <path d="M32.5 28.7c1.8 0 3.5.7 4.8 1.8c-1.4-1.6-3.4-2.7-5.8-2.7c-4.1 0-7.5 3.4-7.5 7.5h1c.5-3.7 3.6-6.6 7.5-6.6" opacity=".33">
                  </path>
                  <path d="M36.2 36.2c0-1.3-.6-2.4-1.6-3.1c.4.6.7 1.3.7 2.1c0 2.1-1.7 3.8-3.8 3.8v3.8c.3 0 .6.1.9.1V40c2.1 0 3.8-1.7 3.8-3.8" opacity=".33">
                  </path>
                  <path d="M32.5 51.2c2.1 0 3.8-1.7 3.8-3.8c0-1.3-.6-2.4-1.6-3.1c.4.6.7 1.3.7 2.1c0 2.1-1.7 3.8-3.8 3.8c-.8 0-1.5-.3-2.1-.7c.6 1.1 1.7 1.7 3 1.7" opacity=".33">
                  </path>
                  <path d="M27.9 47.5c-.1-.3-.1-.6-.1-.9H24c0 2.3 1 4.4 2.7 5.8c-1.1-1.3-1.8-3-1.8-4.8l3-.1" opacity=".33">
                  </path>
                  </g>
                </svg>   

              <b>{{ row.value }}</b></div>
            </template>

            <template #cell(rewards)="row">
              <div><b>${{ row.value }}</b></div>
            </template>
            
            </b-table>
          </b-card>
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      //signupformshow:false,
      email: '',
      motivation:'',
      fullname:'',
      newpassword:'',
      confirmpassword:'',
      typeerr:'',
      loading: false,
      submitloading: false,
      code:'',
      message: [false,'',''],
      leaderfields: [
          { key: 'affid', label: 'Affiliate'},
          { key: 'rewards', label: 'Rewards'},
        ],

    };
  },
  mounted(){
    this.$store.dispatch("initialize");
    this.$store.dispatch("getTopRecords", {router: this.$router});
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    ...mapGetters(["getSignUpeState", "getTopFive", "getSpinner"]),

    signupformshow(){
        return this.$store.state.signupformshow;     
    },

    signcodeformshow(){
        return this.$store.state.signcodeformshow;     
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/Affiliate-Dashboard');
    }
  },
  methods: {
    SignUp () {

      this.$validator.validateAll('signupform').then(
        (result) => {
        if (result) {
          this.$store.dispatch("SignUp", {fullname: this.fullname, motivation: this.motivation, email: this.email, password: this.newpassword, router: this.$router});  
        } else {
          this.message = [true, 'Please fix these errors!', 'danger'];
        }
        
      });
      
    },

    verifyEmailSignupCode() {
      this.submitloading = true;
      this.$validator.validateAll('submitcode').then(

      (isValid) => {
        if (isValid) {
          this.submitloading = false;
          this.$store.dispatch('verifyEmailSignupCode', {code: this.code, email:this.email, router: this.$router})
          this.submitloading = false; 
        } else {
          this.message = [true, 'Please fix these errors!', 'danger'];
          this.submitloading = false;
        }
      }, 
      (error) => {
              this.submitloading = false;
              this.message = [true, error, 'danger'];
      });
    },

    reseterr() {
      this.message = [false,'',''];
      this.$store.dispatch('initSignUpState');
    },

    signIn(){
      this.$router.push('/login');

    },
  }

};

</script>

<style scoped>
.fontsize{
  font-size: 12px;
}
.profile-img-card {
  width: 75px;
  height: 75px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 25%;
  -webkit-border-radius: 25%;
  border-radius: 25%;
}

.page {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  z-index: 25;
  width: 100%;
  height: 100%;
  text-align: center;
}
.spinner {
  z-index: 26;
  position: relative;
  top: 50%;
}

h6 {
    color: blue;
  }
</style>