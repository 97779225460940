<template>
    <footer id="footer-stl" class="bg-light">
      <b-navbar type="dark" variant="dark" > 
        <b-navbar-nav class="txt-foot ml-auto mr-auto">
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item to="/Support">Support</b-nav-item>
          <b-nav-item to="/Affiliate-Dashboard">Affiliate Program</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
      <b-navbar type="dark" variant="dark" > 
        <b-navbar-nav class="txt-foot ml-auto mr-auto">
          <b-nav-item to="/Privacypolicy">Privacy</b-nav-item>
          <b-nav-item to="/Cookies">Cookies</b-nav-item>
          <b-nav-item to="/Terms">Terms</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
    <p class="mb-0 p-2 text-secondary text-center txt-foot"> &copy; <span>{{year}}</span> by Silverdor</p>
    </footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
  },
  data() {
    return {
      year:'2021',
    };
  },
  mounted(){
    this.year = new Date().getFullYear();
  },
  computed: {
    ...mapGetters(["getShowHeader"]),
  }
};
</script>

<style scoped>
  #footer-stl{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .txt-foot{
  font-size: 0.8em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 200;
}


</style>