<template>
  <b-container fluid>
    <b-row class=" p-1" style="background-color:#2f2f30;">

        <b-button-group class="ml-auto">
          <b-button
                size="sm"
                variant="link"
                class=" text-secondary"
                @click.prevent="toDashboard"
                >
                <b-icon icon="pie-chart" variant="primary"  font-scale="1.2" aria-hidden="true"></b-icon>
              Dashboard</b-button>
          <b-button
                size="sm"
                variant="link"
                class=" text-secondary"
                @click.prevent="toTutorial"
                >
                <b-icon icon="book" variant="info"  font-scale="1.2" aria-hidden="true"></b-icon>
              Tutorial</b-button>
        <b-button
                size="sm"
                variant="link"
                class=" text-secondary"
                @click.prevent="toSettings"
                >
                <b-icon icon="gear" variant="warning"  font-scale="1.2" aria-hidden="true"></b-icon>
              Settings</b-button>
        
        <b-button
                size="sm"
                variant="link"
                class=" text-secondary"
                @click.prevent="logOut"
                >
                <b-icon icon="box-arrow-right" variant="danger"  font-scale="1.2" aria-hidden="true"></b-icon>
              Logout</b-button>
        </b-button-group>

      </b-row>
    <b-row class="" style="background-color:#2f2f77;">
        <div class="text-secondary"> Silverdor Affiliate Program <span class="text-warning">&#8250;</span> {{$route.name}}</div>
      </b-row>
    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h4>Silverdor Affiliate Program - Quick start tutorial</h4>
          <h6>Introduction:</h6>
          <p>Our legit affiliate program allows you to earn money by promoting the Silverdor software. Once you join our affiliate program you get your unique affiliate link, you can use it to invite your friends to buy Silverdor. You get a commission when they join Silverdor through your link.</p>

          <h6>How much I earn from each purchase my client makes?</h6>
          <p>You get a 15% commission from each purchase of a Silverdor Package through your affiliate link, it's calculated based on the package price (e.g: Medium Package commission $39.95 x 15% = $6.00). <br> We will increase the commission rate to 20% after reaching 50 cleared sales, then 25% when you reach 200 cleared sales. 
          <br>(Note: we provide special partnership commission rates when your results are outstanding, 1000+ sales).</p>

          <h6>How to get My rewards?</h6>
          <p> Approved commissions will be added to your Rewards-Bank, you can withdraw them at any time by clicking the "Withdraw" button (Minimum withdraw is $15). Go to Settings to add your Affiliate Paypal email address, follow the steps to add or change your Paypal address safely. <br>When your client ask for refund the commission will be cancelled and subtracted from your rewards bank.
           </p>
           
          <h6>How to share the Affiliate link?</h6>
          <p>
            You can share your affiliate link directly from your dashbord by clicking the social media platform icons, or copy the link to share it wherever you want.
          </p>
          
          
          </b-col>
      </b-row>
  </b-container>
</template>

<script>

export default {
 
  data() {
    return {
    };
  },

  methods: {

    toTutorial() {
      if(this.$route.path!=="/Tutorial"){
        this.$router.push('/Tutorial');
      }
    },

    toDashboard() {
      this.$router.push('/Affiliate-Dashboard');
    },

    toSettings() {
      this.$router.push('/Settings');
    },

    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }

};

</script>

<style scoped>

  h6 {
    color: blue;
  }
</style>