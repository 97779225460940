<template>
	<b-container fluid class="px-1">    
    <b-row class="justify-content-center" no-gutters>
      <b-col xs="12" md="8" class="p-2">
        <b-tabs fill card vertical>
          <b-tab title="TUTORIALS" disabled>
          </b-tab>
          <b-tab title="IMPORTANT NOTES!" active>
            <b-card-text>
              <h4 style="text-align: center;">IMPORTANT NOTES!</h4><br>
              <p>
                - This Software is just a tool to help you using Telegram APIs to scrape and add users to groups and channels, If you get flagged by Telegram or your accounts APIs are limited or blocked for some reason that only Telegram knows, please don’t ask our support team about why I’m blocked because Only Telegram API Support can answer your questions.<br><br>
              - Our technical support is replying to all questions about Silverdor installation problems or any other issue related to the Silverdor Application (Not Telegram APIs).<br><br>
              <span style="color: red;">- In case of Issues check the last tutorial section first. Silverdor’s support team will not respond to any message about the already explained Telegram ‘Errors’ and the answered FAQs.</span>
              </p>
              
            </b-card-text>
          </b-tab>
          <b-tab title="INTRODUCTION TO SILVERDOR" lazy>
            <b-card-text>
              <h4 style="text-align: center;">INTRODUCTION TO SILVERDOR</h4><br>
              <p>Silverdor is and automation software, developed to handle Telegram group and channel building. Silverdor comes with an all in one pack, helping business owners to target Telegram users. Telegram is one of the most used social media application, so the idea is to let our customer explore this new platform with our powerful software.<br><br>

              With Silverdor you can easily build large communities (Telegram groups and channels), before starting you should have a list of users to add, Silverdor have the capability to scrape users from other groups or your channels and allow you to add them to your own groups or channels, you can also export them as a ‘.csv’ files and save them locally for the next use.<br><br>

              Silverdor never lost your work, you can easily restore your last work and launch the software from the last end point, we will see how does it work later in this tutorial.<br><br>

              The setting area is the most important part of the software, to do the work correctly and safely,  you will learn how to setting up your software before hitting the start button.<br><br>

              Silverdor is a Telegram API based software, we will be with you Step by Step in this tutorial until adding your first member.<br><br>

              In this tutorial we will present things in a logic order, so please follow the same steps and all will be fine:<br><br>

              1) Installation & Activation,<br>
              2) Get your Telegram API<br>
              3) Software Settings<br>
              4) Silverdor features<br>
              5) How to use it?(video)<br>
              6) Errors</p>
            </b-card-text>
          </b-tab>
          <b-tab title="INSTALLATION & ACTIVATION" lazy>
            <b-card-text>
              <h4 style="text-align: center;">INSTALLATION & ACTIVATION</h4><br>
              <p>
                After purchasing the the product, we have sent to you an email including your download links (for windows and Mac OS), and your username and license key.<br><br>

                To install and activate your Silverdor software :<br><br>

                1) Download the software version using the link provided, depending on your<br>
                operating system.<br>
                2) For Windows: Unzip and execute the Silverdor setup , follow the windows installation manager steps. <br>
                For Mac OS: execute the dmg file and drag Silverdor to the applications folder<br>
                3) Make sure you’re connected to the internet before passing to the next step.<br>
                4) Run the software. An activation window will appear, copy/past your ‘username’ and ‘license key’ as provided by email, delete any spaces and then hit ‘activate’.<br>
                (<span style="color: red;">IMPORTANT! Make sure to delete spaces after or before each entry</span>)
              </p>
              <b-img center fluid src="https://silverdormanager.univerhome.com/uploads/media/activation.jpg" alt="Silverdor-activate"></b-img><br>
              <p>
                5) Enjoy.<br><br>
                If you have problems with your installation or with the activation
              <a href="/Support">Contact us</a></p>
             
            </b-card-text>
          </b-tab>
          <b-tab title="GET YOUR TELEGRAM API" lazy>
            <b-card-text>
              <h4 style="text-align: center;">GET YOUR TELEGRAM API</h4><br>
              <p>We think that you have finished with the installation and the activation of the software. Before start using Silverdor you should start with:<br><br>

              1) Create a Telegram account with your phone number ‘+123456789’. If you have already one that’s fine.<br>
              2) Go to  <a href="https://my.telegram.org/auth">https://my.telegram.org/auth</a> use your phone number to create your Telegram application then get your API information.</p><br>
              <b-img center fluid src="https://silverdormanager.univerhome.com/uploads/media/apispace-1024x457.jpg" alt="apis"></b-img><br>
              <p>
                3) Fill out your app form like this example:<br><br><br>
                  – App title : Enter your own title.<br>
                  – Short name: Enter your own short name.<br>
                  – URL: Let it empty it's optional (recommended).<br>
                  – Platform: Enter "Desktop" (Highly recommended).<br><br>
                4) Telegram will provide your API information, you will need just these two codes:
                the ‘api_id‘ and the ‘api_hash‘, example:<br><br>
                api_id: 111111<br>
                api_hash: 6e9ef2a436kkkke174d2fb7bebba7a5c<br><br>
                Now you have your api code related to your Telegram account. (each account has a unique  API id/hash)
              </p>

            </b-card-text>
          </b-tab>
          <b-tab title="SOFTWARE SETTINGS" lazy>
            <b-card-text>
              <h4 style="text-align: center;">SOFTWARE SETTINGS</h4><br>
              <p>Now you have already your telegram account created and you have get your API (see ‘Get your Telegram API’ section):<br><br>

              To setup your software you should open the Silverdor settings window:<br> 
              - On Windows click file>Settings <br>
              - On Mac click the Apple icone(top-left of your screen)>preferences<br><br>

              Setep 1:  API tab<br><br>

              In the API tab you should enter your api_id, api_hash and the phone number related to your telegram account, For example:<br><br>
              api_id: 111111<br>
              api_hash: 6e9ef2a436kkkke174d2fb7bebba7a5c<br>
              phone number: +123456789<br>
              (<span style="color: red;">IMPORTANT! Make sure to delete spaces after or before each entry</span>)</p>
              <b-img center fluid src="https://silverdormanager.univerhome.com/uploads/media/api-set.jpg" alt="apis"></b-img><br>
              <p>Step 2: Add Settings tab<br>

              As you see in this tap there are 4 values to enter:<br><br>

              Add limit: This value represents the number of users to add before the long delay. For example if this value = 10, the software will add 10 users then stop for the entered long delay (seconds) then start adding the next 10.<br><br>
              Daily limit: This is the total number of users to add by day.<br><br>
              Short delay: The period of sleep time (in seconds) between each user add. the first value is the minimum and the second is the maximum, the software will chose randomly a value between them.<br><br>
              Long delay: The period of sleep time (in seconds) after reaching the Add limit. the first value is the minimum and the second is the maximum, the software will choose randomly a value between them.</p><br>
              <b-img center fluid src="https://silverdormanager.univerhome.com/uploads/media/ssdrr.jpg" alt="apis"></b-img><br><br>
              <p>
                If you have a proxy (optional) :<br>
                Enter your proxy IP, Port, and Username & Password (for dedicated proxies). Let them empty if you don’t want to connect via proxy.<br><br>

                Click OK to save settings. To update settings just follow the same steps. To cancel just click Cancel.
              </p>
            </b-card-text>
          </b-tab>
          <b-tab title="SILVERDOR FEATURES" lazy>
            <b-card-text>
              <h4 style="text-align: center;">SILVERDOR FEATURES</h4><br>
              <p>Silverdor provide a nice and flexible interface, there are 3 main areas in the software window:</p><br>
              <b-img center fluid src="https://silverdormanager.univerhome.com/uploads/media/silverdor-software.jpg" alt="apis"></b-img><br><br>
              <p>Area 1:<br><br>

              This area is reserved for the Telegram web application, it’s integrated with our software, to allow you login with your Telegram account. You can use the current account already added to the settings, or any other account. You can interact with your Telegram while the automation is working.<br><br>

              Area 2:<br><br>

              Here is a view of the list of users that will be added to your group, the list show for each user the following information (username, user id, access hash, full name, chat, chat id).<br><br>

              How can I get this list?<br>
              The List could be scraped easily with our integrated scraping tool, click Tools > Scrap from Telegram , or click the ‘Scrap from Telegram’ in the Area 3. The second way to get it is by importing an existing CSV file (we will explain all in The Area 3)<br><br>

               

              Area 3:<br><br>

              These is your control bord, it contains buttons: ‘Start’, ‘Stop’, ‘Import (csv)’, ‘Export (csv)’, ‘Scrap from Telegram’ And the Visual  daily progress bar, and a live Status report about what’s happening.<br><br>

              The control bord will visualize the current tasks done and give you a quick report about errors and Telegram alerts. This is the way how you will control and communicate with the software.<br><br>

              Start: Click this button to add the list.<br>
              Stop: Stop all program tasks.<br>
              Import (csv): Import a ‘.csv’ file.<br>
              Export (csv): Export the showing list in Area 2 to a ‘.csv’ file.<br>
              Scrap from Telegram: Scrape users of any Telegram group or your channel.<br>
              Visual daily progress bar: Show the daily progress rate.<br>
              Status: Show a live status about what the software tasks and coming errors.</p> 
            </b-card-text>
          </b-tab>
          <b-tab title="HOW TO USE IT? (VIDEO)">
            <b-card-text>
              <h4 style="text-align: center;">HOW TO USE IT? (VIDEO)</h4><br>
              <b-embed 
              type="video" 
              aspect="16by9" 
              controls >    
                <source src="https://silverdormanager.univerhome.com/uploads/media/ANIM1.mp4" type="video/mp4">
              </b-embed><br>

          <p>Note: Settings shown on this video are not a recommendation. Read the entire tutorial before using the software.<br><br>

          After Installing, activating, setting up Silverdor, now we will show a step by step tutorial about how to practically use the software:<br><br>

          Step1:<br><br>

          Firstly create your own group or channel if you have one already all right. Second thing, search groups that you will scrape users from.<br><br>

          Then, using your Telegram account added to settings, join groups where you will add members to, or scrape members from. For example you want to add members to the group A or scrape members from it, the first thing to do is to join group A with the account added to settings. (Same things for channel, but you need to be admin to scrape)<br><br>

          Why is this important?<br><br>

          When you click Start button, The software will detect the list of groups/channels that you’re joining, and show them to you to select the group/channel where members will be added.<br><br>

          When you click Scrap from Telegram button, The software will detect the list of groups/channels that you’re joining, and show them to you to select the group/channel where members will be scraped from.<br><br>

          Step2:<br><br>

          Add the user list, actually you have two method to add them:<br><br>

          a) Add them from a csv file already saved in your device. by clicking Import (csv) button. Select the saved file and add it to the list, automatically the list will be shown on Area 2 (see Silverdor features’s section)<br><br>

          b) Click Scrap from Telegram button, the software will show you a list of  groups that you joined (step1). Select one and click ok. the softawre will scrape all group/channel ‘s members and show them on Area2 (see Silverdor features’s section).<br><br>

          Step3:<br><br>

          Click Start button and let the software do the rest.<br><br>

          Note: maybe this Verification window appears telling you to enter a code:</p><br>

          <b-img center fluid src="https://silverdormanager.univerhome.com/uploads/media/silverdor-verif.jpg" alt="apis"></b-img><br><br>

          <p>This is a Telegram verification process, you will receive a message in your telegram (account used with the software) enter the code and hit OK. If you have activated the 2FA authentication of your account another small window will ask for your account password.</p>
            </b-card-text>
          </b-tab>

          <b-tab title="FAQ & ERRORS" lazy>
            <b-card-text>
              <h4 style="text-align: center;">FAQ & ERRORS</h4><br>
              <p>
                <b>Error: Connection problem</b><br><br>

                This error will shown if the software couldn’t connect. You should verify your network connection. Sometimes your account is temporarily blocked by Telegram.<br><br>

                <b>Error: Scraping Connection Error:</b><br><br>
                This issue is coming from Telegram API system, temporarily rejecting your connection, 
                please take a break then try again. or try with another account.<br><br>

                <b>Error: Process stopped! Admin privileges are required to add to channels</b><br><br>

                You’re not allowed to add users to a channel without admin privileges.<br><br>

                <b>Error: Admin privileges are required to scrape channels</b><br><br>

                You’re not allowed to scrape users from a channel without admin privileges.<br><br>

                <b>FloodError:</b><br><br>

                It means your account has been temporarily flagged from adding by Telegram for a period of time. Silverdor automatically stop the process and restart after 48 hours. You can wait until unlocking your account or stop the software and use a another account.<br><br>

                <b>Error: List Scraped with different account</b><br><br>

                You can’t use a list already scraped with another account. The users should be added with the same account you used to scrape them with.<br><br>

                Check also if the ‘access hash’ column contain wrong data format (e.g: ‘5.43333444’ instead of  ‘543333444’) or if other columns contain wrong data, this causes the same error, export the list and correct it then import it again.<br><br>

                <b>Unexpected Error:</b><br><br>

                This error shown when adding users. If you see this error multiple times for every user add, that means the user you tried to add is already reach the channels/supergroups limit (he can't join more than that limit), Telegram API rejects your adding requests. Silverdor skips this user and try to add the next one. If there is no progress, try to scrape another group.<br><br><br>

                <b>FAQs:</b><br><br>

                <b>Can Silverdor team unblock me from adding?</b><br>
                Please consider that Silverdor team has no control on Telegram side, Silverdor is an automation interface between you and Telegram. All messages or errors received are coming from Telegram not Silverdor.<br><br>


                <b>Can I increase the speed, why some parameters are limited?</b><br>
                We designed the software with the minimal and maximal values allowed by Telegram.<br>
                You can't add more than 50 per day per account (Telegram limitation). We recommend using other accounts to scale up.<br>
                The default short delay & long delay are the minimal safe values to avoid the ban from Telegram.<br><br>

                <b>I can’t see my channel/group in the list?</b><br>

                If you can’t see your channel/group switch them to public mode. If they are newly created or joined, post something to update your chat list in the Telegram servers.<br><br>

                <b>Can I import and edit a CSV file?</b><br>

                You can export, edit csv files as you want and import them again. Use only files exported form Silverdor and edit them preferably with Notepad or similar text editors, Not Microsoft Excel because he changes the csv data format causing issues.
              </p>
            </b-card-text>
          </b-tab>

        </b-tabs>
      </b-col>
    </b-row>
	</b-container> 
</template>

<script>
export default {
 
  data() {
    return {
    };
  },

  methods:{
 
  },

  components: { 
  },

  computed: {
  }
};
</script>
<style scoped>


</style>