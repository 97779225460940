import { render, staticRenderFns } from "./Cryptopay.vue?vue&type=template&id=cc076812&scoped=true&"
import script from "./Cryptopay.vue?vue&type=script&lang=js&"
export * from "./Cryptopay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc076812",
  null
  
)

export default component.exports