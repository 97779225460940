<template>
  <div>
    <b-navbar id="topheader" toggleable="md" type="dark" variant="transparent">
      <b-navbar-brand href="https://silverdor.com/">
        <img src="../assets/icon.png"   height="75" alt="Speechor">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item class = "txt-head" to="/">Home</b-nav-item>
          <b-nav-item class = "txt-head" @click.prevent="scrollto" to="/#pricing">Pricing</b-nav-item>
          <b-nav-item class = "txt-head" to="/Reviews">Reviews</b-nav-item>
          <b-nav-item class = "txt-head" to="/Support">Support</b-nav-item>
          <b-nav-item v-show="loggedIn" class = "txt-head" to="/Affiliate-Dashboard">Affiliate-Dashboard</b-nav-item>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      
    };
  },
  methods:{
    scrollto(){
      document.getElementById('pricing').scrollIntoView();
    }
  },
  computed: {
    ...mapGetters(["getShowHeader"]),
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  }
  
};
</script>

<style scoped>
#nav a.router-link-exact-active {
  color: white;
}

@media not screen and (max-width: 767px) {
  #topheader{
    padding-right: 5%;
    padding-left: 5%;
  }
}

#nav-brand{
  margin-left: 30px;
}

.txt-head{
  display: block;
  font-size: 1.2em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 500;
}

</style>