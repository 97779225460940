import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import VeeValidate from 'vee-validate';
import "./plugins/bootstrap-vue";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import App from "./App.vue";
import router from "./router";
import VueAnalytics from 'vue-analytics';
import VueGtag from "vue-gtag";
import store from "./store";
import VueFacebookPixel from 'vue-analytics-facebook-pixel';

Vue.use(VueFacebookPixel, { router })

Vue.analytics.fbq.event('PageView')

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(VueGtag, {
  config: { id: "AW-710213325" }
});

Vue.use(VueAnalytics, {
    id: 'UA-113971104-1',
    router
});

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields'
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
