<template>
  <b-container fluid>
    <b-row class=" p-1" style="background-color:#2f2f30;">

        <b-button-group class="ml-auto">
          <b-button
                size="sm"
                variant="link"
                class=" text-secondary"
                @click.prevent="toDashboard"
                >
                <b-icon icon="pie-chart" variant="primary"  font-scale="1.2" aria-hidden="true"></b-icon>
              Dashboard</b-button>
          <b-button
                size="sm"
                variant="link"
                class=" text-secondary"
                @click.prevent="toTutorial"
                >
                <b-icon icon="book" variant="info"  font-scale="1.2" aria-hidden="true"></b-icon>
              Tutorial</b-button>
        <b-button
                size="sm"
                variant="link"
                class=" text-secondary"
                @click.prevent="toSettings"
                >
                <b-icon icon="gear" variant="warning"  font-scale="1.2" aria-hidden="true"></b-icon>
              Settings</b-button>
        
        <b-button
                size="sm"
                variant="link"
                class=" text-secondary"
                @click.prevent="logOut"
                >
                <b-icon icon="box-arrow-right" variant="danger"  font-scale="1.2" aria-hidden="true"></b-icon>
              Logout</b-button>
        </b-button-group>

      </b-row>
      <b-row class="" style="background-color:#2f2f77;">
        <div class="text-secondary"> Silverdor Affiliate Program <span class="text-warning">&#8250;</span> {{$route.name}}</div>
      </b-row>
      <b-row>
            <b-alert
              style="width: 100%; border-radius: 0px;"
              :show="!getpplexist"
              dismissible
              fade
              variant="info"
            >Important! To start receiving money add your Paypal Email from the <b>Settings</b> tab.
             </b-alert>
            
      </b-row>
      <b-row>
            <b-alert
            style="width: 100%; border-radius: 0px;"
              :show="getdismissCountDown"
              dismissible
              fade
              :variant="getWMessage[0]"
              @dismiss-count-down="countDownChanged"
            >
              <p>{{getWMessage[1]}}</p>
                This alert will disappear in {{ getdismissCountDown }} seconds...
              <b-progress
                :variant="getWMessage[0]"
                :max="10"
                :value="getdismissCountDown"
                height="4px"
              ></b-progress>
            </b-alert>
            
      </b-row>
      
      <aff-widget ></aff-widget>
     
      <b-row  no-gutters>
        <!-- text editor -->
        <b-col xs="12" md="6" class="p-2">
          <b-card header-tag="header" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0">
              <template #header>
                <p class="mb-0 text-secondary">Invite Link</p>
              </template>
              <!--card-body-->
            <div >
              <h6 class=" text-secondary">Invite Link</h6>
              <b-form-group class="mb-4">
                <b-input-group size="sm">
                  <b-form-input
                    id="linkcopy"
                    size="sm"
                    type="text"
                    name="invitelink"
                    :value="'https://silverdor.com/?r='+getaffId"
                    disabled
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button size="sm" v-b-tooltip.bottom :title="linkCopy" variant="secondary" @click.prevent="copyText('linkcopy')" class="mr-1">

                    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" id="referral-link-copy"><path d="M8 15.6H3.3c-1.1 0-2.1-.9-2.1-2.1V6.8c0-1.1.9-2.1 2.1-2.1H8c1.1 0 2.1.9 2.1 2.1v6.7c0 1.1-1 2.1-2.1 2.1zM3.3 6.2c-.3 0-.5.3-.5.6v6.7c0 .3.3.6.6.6H8c.3 0 .6-.3.6-.6V6.8c0-.3-.3-.6-.6-.6z"></path><path d="M12.6 11.6H12c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h.6c.1 0 .3-.2.3-.6V2.8c0-.4-.2-.6-.3-.6H7.4c-.1 0-.3.2-.3.6s-.3.8-.8.8-.7-.4-.7-.8c0-1.1.8-2.1 1.8-2.1h5.2c1 0 1.8.9 1.8 2.1v6.7c0 1.1-.8 2.1-1.8 2.1z"></path></svg>
                  
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <h6 class=" text-secondary">Invite Code</h6>
              <b-form-group class="mb-4">
                <b-input-group size="sm">
                  <b-form-input
                    id="affidcopy"
                    size="sm"
                    type="text"
                    name="invitelink"
                    :value="getaffId"
                    disabled
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button size="sm" v-b-tooltip.bottom :title="affidCopy" variant="secondary" @click.prevent="copyText('affidcopy')" class="mr-1">

                    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" id="referral-link-copy"><path d="M8 15.6H3.3c-1.1 0-2.1-.9-2.1-2.1V6.8c0-1.1.9-2.1 2.1-2.1H8c1.1 0 2.1.9 2.1 2.1v6.7c0 1.1-1 2.1-2.1 2.1zM3.3 6.2c-.3 0-.5.3-.5.6v6.7c0 .3.3.6.6.6H8c.3 0 .6-.3.6-.6V6.8c0-.3-.3-.6-.6-.6z"></path><path d="M12.6 11.6H12c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h.6c.1 0 .3-.2.3-.6V2.8c0-.4-.2-.6-.3-.6H7.4c-.1 0-.3.2-.3.6s-.3.8-.8.8-.7-.4-.7-.8c0-1.1.8-2.1 1.8-2.1h5.2c1 0 1.8.9 1.8 2.1v6.7c0 1.1-.8 2.1-1.8 2.1z"></path></svg>
                  
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <h6 class=" text-secondary">Share</h6>

              <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
                <a :href="'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsilverdor.com%2F%3Fr%3D'+getaffId" id="share-facebook" class="mr-4 mb-2 AT-IV" target="_blank" rel="noreferrer" data-testid="share-facebook">
                  <svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" width="36" height="36"><path d="M37.546 18.773C37.546 8.403 29.142 0 18.773 0 8.403 0 0 8.404 0 18.773c0 9.372 6.864 17.138 15.84 18.546v-13.12h-4.767v-5.426h4.767v-4.136c0-4.704 2.801-7.304 7.091-7.304 2.053 0 4.202.367 4.202.367v4.62h-2.369c-2.332 0-3.058 1.448-3.058 2.933v3.52h5.207L26.08 24.2h-4.375v13.119c8.976-1.408 15.84-9.174 15.84-18.546z" fill="#1877F2"></path><path d="M26.08 24.2l.832-5.426h-5.206v-3.52c0-1.485.726-2.933 3.058-2.933h2.368V7.7s-2.149-.367-4.202-.367c-4.29 0-7.091 2.6-7.091 7.304v4.136h-4.767V24.2h4.767v13.12c.957.15 1.936.227 2.933.227.998 0 1.977-.077 2.934-.227V24.2h4.374z" fill="#fff"></path></svg>
                </a>

                <a :href="'https://twitter.com/intent/tweet?text=https%3A%2F%2Fsilverdor.com%2F%3Fr%3D'+getaffId" id="share-twitter" class="mr-4 mb-2 AT-IV" target="_blank" rel="noreferrer" data-testid="share-twitter"><svg viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" width="36" height="36"><path d="M26.24 51.495c13.94 0 25.24-11.303 25.24-25.247C51.48 12.304 40.18 1 26.24 1 12.3 1 1 12.304 1 26.248s11.3 25.247 25.24 25.247z" fill="#55ACEE" stroke="#55ACEE" stroke-miterlimit="10"></path><path d="M11.942 35.764c2.918.096 5.52-.702 8.212-2.604-2.54-.583-4.524-1.458-5.329-3.974l2.12-.338c-2.465-1.32-4.324-2.805-4.406-5.74l2.035.321c-2.17-2.26-2.348-4.795-1.411-7.92 3.493 3.71 7.373 5.957 12.432 6.285 0-.578-.009-1.03.002-1.482.106-4.483 5.034-7.515 8.797-5.19 1.702 1.052 4.86.09 5.157.002-.204 1.307-1.151 1.948-1.877 2.963.813-.184 2.053-.428 2.866-.613.048.07-3.002 2.141-3.002 4.308.074 6.264-4.594 13.213-11.008 15.447-4.253 1.48-10.312 1.394-14.588-1.465z" fill="#F3FFFB"></path></svg></a>

                <a :href="'whatsapp://send?text=https%3A%2F%2Fsilverdor.com%2F%3Fr%3D'+getaffId" id="share-whatsapp" class="mr-4 mb-2 AT-IV" target="_blank" rel="noreferrer" data-testid="share-whatsapp"><svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" width="36" height="36"><path d="M18.923 37.546c10.369 0 18.774-8.405 18.774-18.773S29.29 0 18.922 0 .15 8.405.15 18.773s8.405 18.773 18.773 18.773z" fill="#25D366"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M19.542 29.217h-.005c-1.868 0-3.704-.47-5.334-1.359L8.285 29.41l1.584-5.784a11.143 11.143 0 01-1.49-5.58c.002-6.156 5.01-11.163 11.163-11.163a11.09 11.09 0 017.896 3.274 11.095 11.095 0 013.267 7.897c-.002 6.153-5.008 11.16-11.163 11.163zm-5.066-3.38l.34.201a9.263 9.263 0 004.722 1.293h.003c5.114 0 9.276-4.162 9.278-9.278a9.223 9.223 0 00-2.714-6.564 9.215 9.215 0 00-6.56-2.72c-5.118 0-9.28 4.161-9.282 9.277-.001 1.753.49 3.46 1.418 4.937l.221.351-.937 3.424 3.511-.92zm10.308-5.38c.195.095.326.158.382.252.07.116.07.674-.162 1.326-.233.652-1.347 1.246-1.883 1.326-.48.072-1.089.102-1.757-.11-.405-.129-.925-.3-1.59-.588-2.615-1.129-4.382-3.663-4.716-4.142l-.049-.07-.002-.003c-.148-.197-1.137-1.516-1.137-2.882 0-1.285.631-1.958.922-2.268l.055-.059c.255-.279.557-.349.743-.349.186 0 .372.002.535.01h.062c.163 0 .365-.001.565.479l.309.748c.24.584.505 1.23.551 1.323.07.14.117.302.024.489l-.04.079c-.07.143-.12.247-.24.386-.046.054-.094.113-.142.172-.096.116-.192.233-.276.316-.14.14-.285.29-.122.57.163.279.723 1.192 1.552 1.932a7.203 7.203 0 002.059 1.301c.076.034.138.06.184.083.279.14.441.117.604-.07.163-.185.697-.814.883-1.093.186-.28.372-.233.628-.14s1.627.768 1.906.908l.152.075z" fill="#FDFDFD"></path></svg></a>

                <a :href="'https://telegram.me/share/url?url=https%3A%2F%2Fsilverdor.com%2F%3Fr%3D'+getaffId" id="share-telegram" class="mr-4 mb-2 AT-IV" target="_blank" rel="noreferrer" data-testid="share-telegram"><svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" width="36" height="36"><path d="M19.226 37.546C29.594 37.546 38 29.141 38 18.773S29.594 0 19.226 0 .453 8.405.453 18.773s8.405 18.773 18.773 18.773z" fill="url(#telegram_svg__paint0_linear_2241_84289)"></path><path d="M7.445 19.692c2.195-1.209 4.644-2.218 6.933-3.232 3.938-1.66 7.892-3.293 11.885-4.813.777-.258 2.173-.512 2.31.64-.075 1.63-.384 3.25-.595 4.87-.537 3.563-1.157 7.114-1.762 10.666-.209 1.182-1.69 1.795-2.639 1.038-2.279-1.54-4.575-3.064-6.825-4.639-.736-.748-.053-1.824.605-2.358 1.877-1.85 3.867-3.422 5.646-5.367.48-1.158-.938-.182-1.405.117-2.57 1.77-5.076 3.65-7.785 5.206-1.384.761-2.997.11-4.38-.314-1.24-.514-3.057-1.031-1.988-1.814z" fill="#fff"></path><defs><linearGradient id="telegram_svg__paint0_linear_2241_84289" x1="14.535" y1="1.566" x2="5.149" y2="23.466" gradientUnits="userSpaceOnUse"><stop stop-color="#37AEE2"></stop><stop offset="1" stop-color="#1E96C8"></stop></linearGradient></defs></svg></a>
              </div>
            </div>
                   
          </b-card>
        </b-col>   
        <!-- /text editor -->
        <!-- setsection -->
        <b-col xs="12" md="6"  class="p-2 text-light">
          <b-card header-tag="header" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0">
              <template #header>
                <p class="mb-0 text-secondary">Top 5 Affiliates (Worldwide)</p>
              </template>
              <!--card-body-->
              <b-table
              class="text-light"
              show-empty
              striped
              dark
              small
              stacked="md"
              ref="tableRef"
              :items="getTopFive"
              :fields="leaderfields" 
            > 
            <template #cell(affid)="row">
              <div class="w-100">
                  <svg v-if="row.index == 0" width="30px" height="30px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione" preserveAspectRatio="xMidYMid meet">
                  <path fill="#428bc1" d="M46.1 2L34.8 18.9h5.6L53 2z">
                  </path>
                  <path fill="#e8e8e8" d="M40.4 2L29.2 18.9h5.6L46.1 2z">
                  </path>
                  <path fill="#ed4c5c" d="M34.8 2L23.6 18.9h5.6L40.4 2z">
                  </path>
                  <g fill="#ffce31">
                  <circle cx="32" cy="41.4" r="20.6">
                  </circle>
                  <path d="M21.7 15.1c-.5 0-.9.4-.9.9v8.4c0 .5.4.9.9.9h20.6c.5 0 .9-.4.9-.9V16c0-.5-.4-.9-.9-.9H21.7m19.7 6.6c0 .5-.4.9-.9.9H23.6c-.5 0-.9-.4-.9-.9v-3.8c0-.5.4-.9.9-.9h16.9c.5 0 .9.4.9.9v3.8">
                  </path>
                  </g>
                  <path d="M14.3 43.8c0-11.2 8.6-20.3 19.6-21.1c-.5 0-1.1-.1-1.6-.1c-10.5 0-19 8.5-19 19.1c0 3.1.8 6.1 2.1 8.7c-.7-2.1-1.1-4.3-1.1-6.6" opacity=".5" fill="#89664c">
                  </path>
                  <path d="M39.9 57.3C49.2 51.1 52 39 46.6 29.6l.9 1.2c5.8 8.6 3.4 20.3-5.3 26.2c-2.6 1.8-5.5 2.8-8.4 3.1c2.2-.6 4.2-1.5 6.1-2.8" opacity=".33" fill="#ffffff">
                  </path>
                  <path fill="#ed4c5c" d="M23.6 18.9h5.6L17.9 2H11z">
                  </path>
                  <path fill="#e8e8e8" d="M29.2 18.9h5.6L23.6 2h-5.7z">
                  </path>
                  <path opacity=".5" fill="#3e4347" d="M32.9 4.8L30.1 9l6.6 9.9h3.7l1-1.4z">
                  </path>
                  <path fill="#428bc1" d="M34.8 18.9h5.6L29.2 2h-5.6z">
                  </path>
                  <g fill="#89664c">
                  <path d="M26.8 35.2v1c1 0 1.9-.2 2.8-.6v-1.4c-.8.6-1.7.9-2.8 1" opacity=".5">
                  </path>
                  <path opacity=".5" d="M33.4 28.7h.9v21.6h-.9z">
                  </path>
                  <path opacity=".5" d="M37.2 51.2V54H26.8v1h11.3v-3.8z">
                  </path>
                  </g>
                  <g fill="#ffffff">
                  <path opacity=".33" d="M34.3 50.3h2.8v.9h-2.8z">
                  </path>
                  <path d="M30.6 28.7h2.8v-.9h-3.8c0 2.1-1.7 3.8-3.8 3.8v3.7c.3 0 .6 0 .9-.1v-2.8c2.2.1 3.9-1.6 3.9-3.7" opacity=".33">
                  </path>
                  <path d="M30.6 51.2v-16c-.3.2-.6.3-.9.5v14.6h-3.8V54h.9v-2.8h3.8" opacity=".33">
                  </path>
                  </g>
                  </svg>
                
               <svg v-if="row.index == 1" width="30px" height="30px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione" preserveAspectRatio="xMidYMid meet">
                <path fill="#428bc1" d="M46.1 2L34.8 18.9h5.6L53 2z">
                </path>
                <path fill="#e8e8e8" d="M40.4 2L29.2 18.9h5.6L46.1 2z">
                </path>
                <path fill="#ed4c5c" d="M34.8 2L23.6 18.9h5.6L40.4 2z">
                </path>
                <g fill="#b2c1c0">
                <circle cx="32" cy="41.4" r="20.6">
                </circle>
                <path d="M21.7 15.1c-.5 0-.9.4-.9.9v8.4c0 .5.4.9.9.9h20.6c.5 0 .9-.4.9-.9V16c0-.5-.4-.9-.9-.9H21.7m19.7 6.6c0 .5-.4.9-.9.9H23.6c-.5 0-.9-.4-.9-.9v-3.8c0-.5.4-.9.9-.9h16.9c.5 0 .9.4.9.9v3.8">
                </path>
                </g>
                <path d="M14.3 43.8c0-11.2 8.6-20.3 19.6-21.1c-.5 0-1.1-.1-1.6-.1c-10.5 0-19 8.5-19 19.1c0 3.1.8 6.1 2.1 8.7c-.7-2.1-1.1-4.3-1.1-6.6" opacity=".5" fill="#3e4347">
                </path>
                <path d="M39.9 57.3C49.2 51.1 52 39 46.6 29.6c.3.4.6.8.9 1.3c5.8 8.6 3.4 20.3-5.3 26.2c-2.6 1.8-5.5 2.8-8.4 3.1c2.2-.7 4.2-1.6 6.1-2.9" opacity=".33" fill="#ffffff">
                </path>
                <path fill="#ed4c5c" d="M23.6 18.9h5.6L17.9 2H11z">
                </path>
                <path fill="#e8e8e8" d="M29.2 18.9h5.6L23.6 2h-5.7z">
                </path>
                <path opacity=".5" fill="#3e4347" d="M32.9 4.8L30.1 9l6.6 9.9h3.7l1-1.4z">
                </path>
                <path fill="#428bc1" d="M34.8 18.9h5.6L29.2 2h-5.6z">
                </path>
                <g fill="#3e4347">
                <path d="M31.5 31.5c-2.1 0-3.8 1.7-3.8 3.7H25c0 .3-.1.6-.1.9h3.8c0-2.1 1.7-3.8 3.8-3.8c.8 0 1.5.3 2.1.7c-.7-.8-1.8-1.5-3.1-1.5" opacity=".5">
                </path>
                <path opacity=".5" d="M39 54H25v1h15v-3.8h-1z">
                </path>
                <path d="M31.5 42.8c-2.1 0-3.8 1.7-3.8 3.8v3.8h.9v-2.8c0-2.1 1.7-3.8 3.8-3.8c4.1 0 7.5-3.4 7.5-7.5c0-2.3-1-4.4-2.7-5.8c1.1 1.3 1.8 3 1.8 4.8c0 4.1-3.3 7.5-7.5 7.5" opacity=".5">
                </path>
                </g>
                <g fill="#ffffff">
                <path opacity=".33" d="M28.7 50.3H39v.9H28.7z">
                </path>
                <path d="M32.5 40c2.1 0 3.8-1.7 3.8-3.8c0-1.3-.6-2.4-1.6-3.1c.4.6.7 1.3.7 2.1c0 2.1-1.7 3.8-3.8 3.8c-4.1 0-7.5 3.4-7.5 7.5V54h.9v-6.6c0-4.1 3.3-7.4 7.5-7.4" opacity=".33">
                </path>
                <path d="M32.5 28.7c1.8 0 3.5.7 4.8 1.8c-1.4-1.6-3.4-2.7-5.8-2.7c-4.1 0-7.5 3.4-7.5 7.5h1c.5-3.7 3.6-6.6 7.5-6.6" opacity=".33">
                </path>
                </g>
                </svg>
                <svg v-if="row.index == 2" width="30px" height="30px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione" preserveAspectRatio="xMidYMid meet">
                  <path fill="#428bc1" d="M46.1 2L34.8 18.9h5.6L53 2z">
                  </path>
                  <path fill="#e8e8e8" d="M40.4 2L29.2 18.9h5.6L46.1 2z">
                  </path>
                  <path fill="#ed4c5c" d="M34.8 2L23.6 18.9h5.6L40.4 2z">
                  </path>
                  <g fill="#d3976e">
                  <circle cx="32" cy="41.4" r="20.6">
                  </circle>
                  <path d="M21.7 15.1c-.5 0-.9.4-.9.9v8.4c0 .5.4.9.9.9h20.6c.5 0 .9-.4.9-.9V16c0-.5-.4-.9-.9-.9H21.7m19.7 6.6c0 .5-.4.9-.9.9H23.6c-.5 0-.9-.4-.9-.9v-3.8c0-.5.4-.9.9-.9h16.9c.5 0 .9.4.9.9v3.8">
                  </path>
                  </g>
                  <path d="M14.3 43.8c0-11.2 8.6-20.3 19.6-21.1c-.5 0-1.1-.1-1.6-.1c-10.5 0-19 8.5-19 19.1c0 3.1.8 6.1 2.1 8.7c-.7-2.1-1.1-4.3-1.1-6.6" opacity=".5" fill="#3e4347">
                  </path>
                  <path d="M39.9 57.3C49.2 51.1 52 39 46.6 29.6l.9 1.2c5.8 8.6 3.4 20.3-5.3 26.2c-2.6 1.8-5.5 2.8-8.4 3.1c2.2-.6 4.2-1.5 6.1-2.8" opacity=".33" fill="#ffffff">
                  </path>
                  <path fill="#ed4c5c" d="M23.6 18.9h5.6L17.9 2H11z">
                  </path>
                  <path fill="#e8e8e8" d="M29.2 18.9h5.6L23.6 2h-5.7z">
                  </path>
                  <path opacity=".5" fill="#3e4347" d="M32.9 4.8L30.1 9l6.6 9.9h3.7l1-1.4z">
                  </path>
                  <path fill="#428bc1" d="M34.8 18.9h5.6L29.2 2h-5.6z">
                  </path>
                  <g fill="#3e4347">
                  <path d="M31.5 31.5c-2.1 0-3.8 1.7-3.8 3.7H25c0 .3-.1.6-.1.9h3.8c0-2.1 1.7-3.8 3.8-3.8c.8 0 1.5.3 2.1.7c-.7-.8-1.8-1.5-3.1-1.5" opacity=".5">
                  </path>
                  <path d="M37.4 41.8c1.6-1.4 2.6-3.4 2.6-5.6c0-2.3-1-4.4-2.7-5.8c1.1 1.3 1.8 3 1.8 4.8c0 2.2-1 4.3-2.6 5.6c1.6 1.4 2.6 3.4 2.6 5.6c0 4.1-3.4 7.5-7.5 7.5c-1.8 0-3.5-.7-4.8-1.8c1.4 1.6 3.4 2.7 5.8 2.7c4.1 0 7.5-3.4 7.5-7.5c-.1-2.1-1.1-4.1-2.7-5.5" opacity=".5">
                  </path>
                  </g>
                  <g fill="#ffffff">
                  <path d="M32.5 28.7c1.8 0 3.5.7 4.8 1.8c-1.4-1.6-3.4-2.7-5.8-2.7c-4.1 0-7.5 3.4-7.5 7.5h1c.5-3.7 3.6-6.6 7.5-6.6" opacity=".33">
                  </path>
                  <path d="M36.2 36.2c0-1.3-.6-2.4-1.6-3.1c.4.6.7 1.3.7 2.1c0 2.1-1.7 3.8-3.8 3.8v3.8c.3 0 .6.1.9.1V40c2.1 0 3.8-1.7 3.8-3.8" opacity=".33">
                  </path>
                  <path d="M32.5 51.2c2.1 0 3.8-1.7 3.8-3.8c0-1.3-.6-2.4-1.6-3.1c.4.6.7 1.3.7 2.1c0 2.1-1.7 3.8-3.8 3.8c-.8 0-1.5-.3-2.1-.7c.6 1.1 1.7 1.7 3 1.7" opacity=".33">
                  </path>
                  <path d="M27.9 47.5c-.1-.3-.1-.6-.1-.9H24c0 2.3 1 4.4 2.7 5.8c-1.1-1.3-1.8-3-1.8-4.8l3-.1" opacity=".33">
                  </path>
                  </g>
                </svg>   

              <b>{{ row.value }}</b></div>
            </template>

            <template #cell(rewards)="row">
              <div><b>${{ row.value }}</b></div>
            </template>
            </b-table>
          </b-card>
        </b-col>
        <!-- /setsection -->
      </b-row>
      <b-row  class="border-top border-secondary">
        <aff-table></aff-table>
      </b-row>

  </b-container>
</template>

<script>
import AffWidgets from "@/components/AffWidgets.vue";
import AffTable from "@/components/AffTable.vue";
import { mapGetters } from "vuex";
export default {
 
  data() {
    return {
      invitelink:'speechor.com/?r=1233211',
      invitecode:'1233211',
      leaderfields: [
          { key: 'affid', label: 'Affiliate'},
          { key: 'rewards', label: 'Rewards'},
        ],
      linkCopy: 'Copy',
      affidCopy: 'Copy',
     
        
   
    };
  },

  components: { 
    'aff-widget': AffWidgets,
    'aff-table': AffTable
  },
  mounted(){
    this.$store.dispatch("getaffRecords", {router: this.$router});
  },
 computed: {
      ...mapGetters(["getTopFive", "getaffsignedup", "getaffId","getdismissCountDown", "getWMessage","getpplexist","getisaffActive"]),
  },
  methods: {

    toSettings() {
      this.$router.push('/Settings');   
    },

    toDashboard() {
      if(this.$route.path!=='/Affiliate-Dashboard'){
        this.$router.push('/Affiliate-Dashboard');
      }
    },

    toTutorial() {
        this.$router.push('/Tutorial');
    },


    countDownChanged(dismissCountDown) {
        this.$store.dispatch("walertcount", {countdown: dismissCountDown});
    },

    copyText(id) {
      var copyText = document.getElementById(id);
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      navigator.clipboard.writeText(copyText.value);
      if(id == "linkcopy"){
        this.linkCopy = "Copied";
        this.affidCopy ="Copy";
      } else {
        this.affidCopy ="Copied";
        this.linkCopy = "Copy";
      }
      
    },

    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
      

   
  }

};

</script>

<style scoped>

 
</style>