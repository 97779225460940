 <template>
  <b-container fluid class="px-1">  
    <b-row class="justify-content-center mx-0">
      <h1 style="text-align: center;color: #fff;">Silverdor Software</h1>
    </b-row>
    <b-row class="justify-content-center mx-0">
      <h2 class="sectitle">Telegram Group & Channel Builder<br>
      One Time Investement</h2>
    </b-row>
    <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="8" class="p-2">
          <b-embed 
          type="video" 
          aspect="16by9" 
          controls 
          autoplay 
          poster="https://silverdormanager.univerhome.com/uploads/media/thumb.jpg">     
          <source src="https://silverdormanager.univerhome.com/uploads/media/silverdorpromox.mp4" type="video/mp4">
          </b-embed>
        </b-col>
    </b-row>

    <b-row class="justify-content-center mt-2 mb-2" no-gutters>
      <b-icon icon="chevron-double-down" variant="warning" font-scale="5" animation="cylon-vertical" aria-hidden="true"></b-icon>
    </b-row>

    <b-row class="justify-content-center mt-4 mb-4" no-gutters> 
      <h1 class="whitetitle" id="pricing">Pricing Details</h1>
    </b-row>
    <b-row class="d-flex justify-content-center" no-gutters>
            <b-col xs="12" md="6" xl="3" class="mt-1 d-flex justify-content-center">
              <b-card
              bg-variant = "secondary"
              no-body
              style="width: 100%; margin-left: 10px; margin-right: 10px"
              >
                <template #header>
                  <h3 class="mb-0 text-center" style="color: #fff;">{{getPrice[0].product}}</h3>
                  <h2 class="m-2 text-center" style="color: #fff;">${{getPrice[0].price}}</h2>
                  <h6 class="m-2 text-center" style="color: #fff;">Lifetime License</h6>
                </template>
                <b-list-group flush>
                  <b-list-group-item variant="secondary">
                      <p class="mb-0 text-center">1 Computer</p>   
                  </b-list-group-item>
                  <b-list-group-item v-for="feature in features" :key="feature" variant="secondary">
                      <P class="mb-0 text-center">{{feature}}</P>
                  </b-list-group-item>
                </b-list-group>
                <b-card-body>
                  <b-button 
                  class="w-100 mt-2"
                  size="md"
                  :disabled="loading"
                  squared
                  id="boost-1"
                  variant="primary"
                  @click.prevent="ordernow(getPrice[0].id)"
                  >
                  <span v-show="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  <span>Order Now</span></b-button>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xs="12" md="6" xl="3" class="mt-1 d-flex justify-content-center">
              <b-card
                bg-variant = "primary"
                no-body
                style="width: 100%; margin-left: 10px; margin-right: 10px"
                >
                <template #header>
                  <h3 class="mb-0 text-center" style="color: #fff;">{{getPrice[1].product}}</h3>
                  <h2 class="m-2 text-center" style="color: #fff;">${{getPrice[1].price}}</h2>
                  <h6 class="m-2 text-center" style="color: #fff;">Lifetime License</h6>
                </template>
                <b-list-group flush>
                  <b-list-group-item variant="secondary">
                      <p class="mb-0 text-center">5 Computers</p>   
                  </b-list-group-item>
                  <b-list-group-item v-for="feature in features" :key="feature" variant="secondary">
                      <P class="mb-0 text-center">{{feature}}</P>
                  </b-list-group-item>
                </b-list-group>

                <b-card-body>
                  <b-button
                  class="w-100 mt-2" 
                  size="lg"
                  :disabled="loading"
                  squared
                  id="boost-1"
                  variant="warning"
                  @click.prevent="ordernow(getPrice[1].id)"
                  >
                  <span v-show="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  <span>Order Now</span></b-button>
                </b-card-body>
        
              </b-card>
            </b-col>
            <b-col xs="12" md="6" xl="3" class="mt-1 d-flex justify-content-center">
              <b-card
                bg-variant = "secondary"
                no-body
                style="width: 100%; margin-left: 10px; margin-right: 10px "
            >
                <template #header>
                  <h3 class="mb-0 text-center" style="color: #fff;">{{getPrice[2].product}}</h3>
                  <h2 class="m-2 text-center" style="color: #fff;">${{getPrice[2].price}}</h2>
                  <h6 class="m-2 text-center" style="color: #fff;">Lifetime License</h6>
                </template>
                <b-list-group flush>
                  <b-list-group-item variant="secondary">
                      <p class="mb-0 text-center">10 Computers</p>   
                  </b-list-group-item>
                  <b-list-group-item v-for="feature in features" :key="feature" variant="secondary">
                      <P class="mb-0 text-center">{{feature}}</P>
                  </b-list-group-item>
                </b-list-group>
                <b-card-body>
                    <b-button 
                  class="w-100 mt-2" 
                  size="md"
                  :disabled="loading"
                  squared
                  id="boost-1" 
                  variant="primary"
                  @click.prevent="ordernow(getPrice[2].id)"
                  >
                  <span v-show="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    <span>Order Now</span></b-button>
                </b-card-body>
            </b-card>
            </b-col>
            <b-col xs="12" md="6" xl="3" class="mt-1 d-flex justify-content-center">
              <b-card
                bg-variant = "primary"
                no-body
                style="width: 100%; margin-left: 10px; margin-right: 10px"
            >
                <template #header>
                  <h3 class="mb-0 text-center" style="color: #fff;">{{getPrice[3].product}}</h3>
                  <h2 class="m-2 text-center" style="color: #fff;">${{getPrice[3].price}}</h2>
                  <h6 class="m-2 text-center" style="color: #fff;">Lifetime License</h6>
                </template>
                <b-list-group flush>
                  <b-list-group-item variant="secondary">
                      <p class="mb-0 text-center">25 Computers</p>   
                  </b-list-group-item>
                  <b-list-group-item v-for="feature in features" :key="feature" variant="secondary">
                      <P class="mb-0 text-center">{{feature}}</P>
                  </b-list-group-item>
                </b-list-group>
                <b-card-body>
                  <b-button
                  class="w-100 mt-2" 
                  size="lg"
                  :disabled="loading"
                  squared
                  id="boost-1"
                  variant="warning"
                  @click.prevent="ordernow(getPrice[3].id)"
                  >
                  <span v-show="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    <span>Order Now</span></b-button>
                </b-card-body>
            </b-card>
            </b-col>     
        </b-row>
        <b-row class="justify-content-center mt-4 mb-4 p-2" no-gutters> 
             <b-button 
                  class="p-2" 
                  size="lg"
                  :disabled="loading"
                  rounded
                  id="crypto" 
                  variant="warning"
                  @click.prevent="crypto"
                  >
                  <span v-show="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    <span>Pay With Cryptocurrency</span></b-button>
        </b-row>

    
    <b-row class="justify-content-center mt-4 mb-4" no-gutters> 
      <h1 class="whitetitle">Silverdor Features</h1>
    </b-row>

    <b-row no-gutters>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
           <b-card
            style="max-width: 25rem;"
            class="mb-4 bg-transparent text-center "
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 5rem;"><b-icon icon="list-check" class="rounded-circle bg-primary p-4" variant="light"></b-icon></div>
               <h2 class="featitle">Telegram Scraper</h2>
              <p>Telegram scraper will help you scrape members from other Telegram groups or your channels, you can scrape the most recent active users filtering them by (1day, 1week or 1month).</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
           <b-card
            style="max-width: 25rem;"
            class="mb-4 bg-transparent text-center "
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 5rem;"><b-icon icon="journal-plus" class="rounded-circle bg-primary p-4" variant="light"></b-icon></div>
              <h2 class="featitle">Telegram Adder</h2>
              <p>The Telegram scraper feature get the users list, then you start adding members to your Telegram groups or channels, based on your settings. Group or channel Members are added automatically. You can run it in your laptop or in a VPS.</p>
            </b-card-text>
          </b-card>
        </b-col>
        
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bg-transparent text-center"
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 5rem;"><b-icon icon="box-arrow-in-down" class="rounded-circle bg-primary p-4" variant="light"></b-icon></div>
               <h2 class="featitle">Import Files</h2>
              <p>You can easily Import members list from a ‘.csv’ file scraped with the Telegram Scraper feature. Silverdor will build your Telegram group or channel using the list uploaded.</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
           <b-card
            style="max-width: 25rem;"
            class="mb-4 bg-transparent text-center "
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 5rem;"><b-icon icon="box-arrow-up" class="rounded-circle bg-primary p-4" variant="light"></b-icon></div>
              <h2 class="featitle">Export Files</h2>
              <p>Silverdor allows you to store your scraped users, and keep the scraped lists saved in your device, for your next Telegram group or channel building project.</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
           <b-card
            style="max-width: 25rem;"
            class="mb-4 bg-transparent text-center "
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 5rem;"><b-icon icon="clock-history" class="rounded-circle bg-primary p-4" variant="light"></b-icon></div>
              <h2 class="featitle">Restore</h2>
              <p>When closing the software for any reason, the work is never lost, you can start again by restoring the last activity. Silverdor can backup all your activities.</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bg-transparent text-center"
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 5rem;"><b-icon icon="sliders" class="rounded-circle bg-primary p-4" variant="light"></b-icon></div>
              <h2 class="featitle">Full Control</h2>
              <p>You have a full control of your software, you can control how the add process will work, amount of users to add to a Telegram group or channel, time control and more features.</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
           <b-card
            style="max-width: 25rem;"
            class="mb-4 bg-transparent text-center "
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 5rem;"><b-icon icon="life-preserver" class="rounded-circle bg-primary p-4" variant="light"></b-icon></div>
              <h2 class="featitle">Support</h2>
              <p>Our support team is ready to help you enjoying the power of Silverdor. We are available 24/7 to answer all your questions and solve any technical issues.</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
           <b-card
            style="max-width: 25rem;"
            class="mb-4 bg-transparent text-center "
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 5rem;"><b-icon icon="shield-check" class="rounded-circle bg-primary p-4" variant="light"></b-icon></div>
              <h2 class="featitle">Safe</h2>
              <p>Silverdor, Telegram group scraper/builder was designed by a professional team of engineers to be 100% safe. We keep updating our software based on the latest Telegram updates. Silverdor allows you to connect via proxies.</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bg-transparent text-center"
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 5rem;"><b-icon icon="signpost-split" class="rounded-circle bg-primary p-4" variant="light"></b-icon></div>
              <h2 class="featitle">Tutorials</h2>
              <p>After becoming one of the Silverdor happy customers , you will get access to the full software documentation giving you more details about: Installation, activation, settings… and how to use the software interface correctly step by step. You will get all details about the software process.</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
      <b-icon icon="chevron-double-down" variant="primary" font-scale="5" animation="cylon-vertical" aria-hidden="true"></b-icon>
    </b-row>




    

  <b-row class="justify-content-center mt-4 mb-4" no-gutters>
        <h1 class="whitetitle">Compatible With Windows/Mac OS</h1>
      </b-row>

      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="8" class="p-2">
          <b-img class="width-img" center fluid src="https://silverdormanager.univerhome.com/uploads/media/Silverdor-mac-windows.png" alt="Silverdor Mac os and Windows image"></b-img>
        </b-col>
      </b-row>
  
    <b-row class="justify-content-center mt-4 mb-4" no-gutters>
        <h1 class="whitetitle">Silverdor In Action</h1>
      </b-row>

      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="8" class="p-2">
          <b-img center fluid src="https://silverdormanager.univerhome.com/uploads/media/silverdor_software.jpg" alt="Silverdor image"></b-img>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
      <b-icon icon="chevron-double-down" variant="primary" font-scale="5" animation="cylon-vertical" aria-hidden="true"></b-icon>
    </b-row>
      <b-row class="justify-content-center mt-4 mb-4" no-gutters>
          <h1 class="whitetitle">Testimonials</h1>
      </b-row>

      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="6" class="p-4">      
              <div style="color: white; min-height: 185px; background:orange; text-align: center;padding: 20px;border-radius: 10px;">
                <h2 style="color: white; text-align: center;">Chris</h2>
              <p>“I was fortunate to test the software before the public release, it will definitely get you hooked. It’s fast and reliable with straight to the point guide…”<br>
              -United Kingdom-</p>
              </div>
        </b-col>
      
        <b-col xs="12" md="6" class="p-4">
              <div style="color: white; min-height: 185px; background:orange; text-align: center;padding: 20px;border-radius: 10px;">
              <h2 style="color: white; text-align: center;">Lisa</h2>
              <p>“The customer support is spectacular, they’ve answered any questions and inquiries I’ve had within hours every single time…”<br>
              -United States-</p></div>
        </b-col>
      </b-row>

      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="6" class="p-4">
              <div style=" color: white; min-height: 185px; background:orange; text-align: center;padding: 20px;border-radius: 10px;">
              <h2 style="color: white; text-align: center;">Fred</h2>
              <p>
              “I’ve been using the software for over two months now and it’s performing exceptionally well, all the features are working as described…”<br>
              -Hong Kong- </p></div>
        </b-col>
   
        <b-col xs="12" md="6" class="p-4">
              <div style=" color: white; min-height: 185px; background:orange; text-align: center;padding: 20px;border-radius: 10px;">
              <h2 style="color: white; text-align: center;">Jack</h2>
              <p>
              “Silverdor Makes connecting with potential fans & clients 100% easier, my content reaches way more people and I’m able to focus more on content creation! Easy to learn program, great help with any issues and the results are incredible!”<br>-Canada-
              </p></div>
        </b-col>
      </b-row> 
      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
      <b-icon icon="chevron-double-down" variant="primary" font-scale="5" animation="cylon-vertical" aria-hidden="true"></b-icon>
    </b-row>
      <b-row class="justify-content-center mt-4 mb-4" no-gutters>
          <h1 class="whitetitle">FAQ</h1>
      </b-row>

      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="6" class="p-4">
              <div style="color: white; min-height: 250px; background:blue;padding: 20px;border-radius: 10px;">
              <h3>Why Silverdor is the best?</h3>
              <p>Building Telegram group or channel is one of the newest internet marketing strategies. Silverdor Telegram scraper and Group and Channel builder comes with a smart software with a flexible interface, putting in your hand a powerful tool to automate all things: scraping and building groups and channels at the same time. Silverdor can build large Telegram groups and channels, where you can promote your products and services. Or if you need to create a discussion community related to your business. The software is one time paid, no monthly charges.</p></div>
        </b-col>
      
        <b-col xs="12" md="6" class="p-4">
              <div style="color: white; min-height: 250px; background:blue;padding: 20px;border-radius: 10px;">
              <h3>Is the software safe?</h3>
              <p>Of course yes, because all activities done are authorized by Telegram. Telegram allows you to build communities and add targeted members to your groups and channels.  Telegram scraper feature will help you scrape from groups or your channels and then add them safely to your groups or channels. Our tutorials explain all to avoid any problems.</p></div>
        </b-col>
      </b-row>

      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="6" class="p-4">
              <div style="color: white; min-height: 250px; background:blue;padding: 20px;border-radius: 10px;">
              <h3>How Silverdor will help my business?</h3>
              <p>People joined your group or channel by the Silverdor software, will be a targeted audience based on your business. For example you want to build a Telegram group or channel about Trading. Basically you will scrape users from groups related to Trading niche, then of course a lot of them will engage with your products and services.</p></div>
        </b-col>
   
        <b-col xs="12" md="6" class="p-4">
              <div style="color: white; min-height: 250px; background:blue;padding: 20px;border-radius: 10px;">
              <h3>Do you have an Affiliate Program?</h3>
              <p>Yes, as an affiliate marketer you can join our affiliate program, commissions start from 15% from each sale you reffer. To join the program <a href="/Signup">signup from here</a></p></div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="6" class="p-4">
              <div style="color: white;min-height: 250px; background:blue;padding: 20px;border-radius: 10px;">
              <h3>Does Telegram allow using bots?</h3>
              <p>Telegram is the only one company who allows using bots. Silverdor is based on the same concept of Telegram bots. So, you can easily automate Telegram group and channel building and let the software do the work.</p></div>
        </b-col>
   
        <b-col xs="12" md="6" class="p-4">
              <div style="color: white;min-height: 250px; background:blue;padding: 20px;border-radius: 10px;">
              <h3>Why Telegram group and channel building?</h3>
              <p>Telegram groups/channels building is one of the best marketing strategies, such as Facebook groups, Telegram allows you to build and manage a community of users under a group or a channel, you can go live with them too, For example if you have a store, creating a Telegram group or channel is a powerful idea to promote your products/services to the Telegram community. Silverdor comes with an All-in-One tool helping you automatically scrape users and build Telegram groups and channels faster than you imagine.</p>
            </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="6" class="p-4">
              <div style="color: white; min-height: 250px; background:blue;padding: 20px;border-radius: 10px;">
              <h3>Do I need advanced computer skills to use Silverdor ?</h3>
              <p>Not at all, you can easily get install and activate Silverdor Telegram Group/Channel Builder, then start using it in some minutes.<br>

              Our tutorials will help you enjoy all the software features. Plus, you will learn how to install, activate and a full description of each software feature.</p></div>
        </b-col>
   
        <b-col xs="12" md="6" class="p-4">
              <div style="color: white;min-height: 250px; background:blue;padding: 20px; border-radius: 10px;">
              <h3>What requirements & operating systems the software support?</h3>
              <p>Silverdor Telegram Group/Channel Builder is supporting:<br>

              <b>RAM/Processor:</b><br>
              Any computer with 2GB of RAM or above.<br>
              <b>Operating systems:</b><br>
              Mac OS: All versions.<br>
              Windows: Windows XP, Windows Vista, Windows 7, 8, 8.1, 10, 11, Windows Server.</p></div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
      <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
    </b-row>
      <b-row class="justify-content-center mt-4 mb-2" no-gutters> 
       <b-button 
            class="p-2" 
            size="lg"
            rounded
            id="scrol23" 
            variant="warning"
            @click.prevent="scrollto"
            >Get Silverdor Now!</b-button>
      </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
 
  data() {
    return {
      referrer : '',
      visible: false,
      loading: false,
      features: ['Telegam scraper','Telegram Adder','Telegram web Integrated','Custom Settings','Custom Proxy','Restore and Backup','CSV import/Export','Support 24/7','Tutorial & Docs']
    };
  },
  mounted() {
      this.$store.dispatch("getprice");
      this.referrer = this.$route.query.r;
      if(this.referrer !== undefined && this.referrer !== '' && this.referrer !== null) {
        //set cookie
        this.setCookie();
      }
  },
  methods:{
    crypto(){
      this.$analytics.fbq.event('silverdorBtcOrderBtn');
      this.$router.push("/crypto-payment");
      
    },
    scrollto(){
      document.getElementById('pricing').scrollIntoView();
    },

    ordernow(id){
        const affCookie = this.getCookie("REFID_AFF_SLDR");
        this.loading = true;
        this.$analytics.fbq.event('silverdorOrderBtn');
        this.$store.dispatch("ordernow", {product: id, referrer: affCookie});
    },

    setCookie() {
      const refcookie = this.getCookie("REFID_AFF_SLDR");
      //set expire time
      const d = new Date();
      d.setTime(d.getTime() + (14 * 24 * 60 * 60 * 1000));
      let expires = "expires="+d.toUTCString();

      if(refcookie == ""){ 
        document.cookie = "REFID_AFF_SLDR="+ this.referrer +";" + expires + ";path=/;SameSite=None;secure";
      }
      
    },

    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {

            const fc = c.substring(name.length, c.length);
            return fc;
          }
        }
        return "";
    },

  },

  components: { 
  },

  computed: {
    ...mapGetters(["getPrice"]),
  }
};
</script>
<style scoped>
 .border-left{
  background: #482b69;
  border: none;
 }
 .sectitle{
  color: #0E0092;
  text-align: center;
 }
 .featitle{
  color: #037bfc;
  text-align: center;
 }
 .whitetitle{
  color: #0E0092;
  text-align: center;
 }
 .price-style{
  text-align: center;
  color: #25964d;
  font-family: 'almendra display',Sans-serif;
  font-weight: 700; 
}
.bg-trans{
  background: transparent;
  border: none;
  padding: 10px 20px;
}
.bg-trans-p6{
  background: transparent;
  border: none;
  padding: 5px 20px;
}
.txt-grey{
  color: white;
}
.txt-grey1{
  color: grey;
}
.iconpostion{
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.bg-orge{
  background-color: #ff9400;
}
.bg-blue1{
  background-color: #007aff;
}
.bg-puplea{
  background-color: #6f42c1;
}
.bg-pupleb{
  background-color: #c7a2fc;
}
.bg-greenb{
background-color: #fff;
}
.bg-img{
  background-color: transparent;
  background-image: linear-gradient(140deg,#1A1060 17%,#f2295b 100%);
}
.width-img{
  max-width: 300px;
}
@media not screen and (max-width: 767px) {
  .padd-x{
    padding-right: 10%;
    padding-left: 10%;
  }
}

</style>