<template>
	<b-container fluid class="px-1">
      <b-row class="justify-content-center mx-auto my-4">
        <b-icon icon="check-circle" scale="5" variant="success"></b-icon>
      </b-row>  
	    <b-row class="justify-content-center mx-0 mt-4">
    	   <h4 style="text-align: center;padding-left: 10%; padding-right: 10%;padding-top: 20px;">Payment Successfully processed! your order is processing now!</h4>
          <p style="text-align: center;padding-left: 10%; padding-right: 10%;padding-top: 20px;"> If you ordered an new license, you will receive your download link and your license credentials in less than 24 hours. If you ordered for upgrade, our team will upgrade your current license and notify you by email when it is done!</p>
	    </b-row>
      <b-row class="justify-content-center mx-0">
        <h1 style="text-align: center;padding-left: 10%; padding-right: 10%;">Thank You!</h1>
      </b-row>
	</b-container>
</template>

<script>
export default {
 
  data() {
    return {
      visible: false
    };
  },

  mounted(){
    this.$analytics.fbq.event('SuccessSilverdorPayment', {currency: "USD", value: 25.00});
    this.$gtag.event('conversion', {
        'send_to': 'AW-710213325/DSptCJK6m7gBEM3909IC',
        'value': 29.95,
        'currency': 'USD',
        'transaction_id': ''
    });

  }
};
</script>
<style scoped>


</style>