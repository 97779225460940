import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({encodingType: 'rc4', isCompression: false});
ls.removeAll();
import { auth } from './auth.module';
import UserService from '../services/user.service';
import AuthService from '../services/auth.service';

Vue.use(Vuex);
export default new Vuex.Store({

  state: {
    something:true,
    mainprice:[
            {text: "Loading...", value: 1255},
          ],
    address: ["","","",""],
    price:[
            {id: 225, price: 29.95, product: "Silverdor Micro Pack"},
            {id: 228, price: 39.95, product: "Silverdor Medium Pack"},
            {id: 229, price: 49.95, product: "Silverdor Mega Pack"},
            {id: 733, price: 85.95, product: "Silverdor Business Pack"}
          ],
    upgrade:[ {text: "Upgrade from Micro to Medium Package[$15]", value: 1255},
              {text: "Upgrade from Micro to Mega Package[$30]", value: 1256},
              {text: "Upgrade from Micro to Business Package[$65]", value: 1258},
              {text: "Upgrade from Medium to Mega Package[$15]", value: 1259},
              {text: "Upgrade from Medium to Business Package[$55]", value: 1261},
              {text: "Upgrade from Mega to Business Package[$45]", value: 1262},
            ],
    showHeader:false,
    showSpinner: false,
    sendCodeState:[false,'',''],
    signUpState:[false,'',''],
    resetPassState:[false,'',''],
    verifiedCode:'',
    verifiedEmail:'',
    codeformshow:true,
    reviews:[],

    //affiliate program
    affrecords:[],
    afftopfive:[],
    affsignedup: false,
    affId: '',
    affBalance:0,
    affpartners: 0,
    affthismonth: 0,
    affalltime: 0,
    affallsales: 0,
    pplemailexist:false,
    isaffActive:true,
    dismissCountDown: 0,
    wmessage:['',''],
    signupformshow: true,
    signcodeformshow: false,

    signupattamps:"0",
    forgotattemps:"0",
    passcodeattemps:"0",
    emailsignupcodeattemps: "0",

    paypalCodeState:[false,'',''],
    paypalCodeSent:false,
    pplemail:['', false],


  },

  plugins: [
  
  createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  mutations: {
    SET_SOMETHING(state, something){
      state.something = something;
    },
    SET_PRICE(state, price){
      state.price = price;
    },
    SET_ADDRESS(state, address){
      state.address = address;
    },
    SET_MAIN(state, mainprice){
      state.mainprice = mainprice;
    },
    SET_UPGRADE(state, upgrade){
      state.upgrade = upgrade;
    },
    SET_SHOW_HEADER(state, showHeader){
      state.showHeader = showHeader;
    },
    SET_CURRENT_UID (state, currentUId){
      state.currentUId = currentUId;
    },
    SET_SPINNER(state, spinner) {
      state.showSpinner = spinner;
    },
    SET_SENDCODESTATE(state, sendCodeState){
      state.sendCodeState = sendCodeState;
    },
    SET_SIGNUPSTATE(state, signUpState){
      state.signUpState = signUpState;
    },
    SET_RESETPASSSTATE(state, resetPassState){
      state.resetPassState = resetPassState;
    },
    SET_VERIFCODE(state, verifiedCode){
      state.verifiedCode = verifiedCode;
    },
    SET_VERIFEMAIL(state, verifiedEmail){
      state.verifiedEmail = verifiedEmail;
    },
    SET_CODEFORMSHOW(state, codeformshow){
      state.codeformshow = codeformshow;
    },
    SET_REVIEWS(state, reviews) {
      state.reviews = reviews;
    },

    //Affiliate program mutations
    SET_AFFRECORDS(state, affrecords) {
      state.affrecords = affrecords;
    },
    SET_TOPFIVE(state, afftopfive) {
      state.afftopfive = afftopfive;
    },

    SET_SIGNEDUP(state, affsignedup) {
      state.affsignedup = affsignedup;
    },
    SET_AFFID(state, affId) {
      state.affId = affId;
    },
    SET_AFF_BALANCE(state, affBalance) {
      state.affBalance = affBalance;
    },
    SET_AFF_THISM(state, affthismonth) {
      state.affthismonth = affthismonth;
    },
    SET_AFF_ALLT(state, affalltime) {
      state.affalltime = affalltime;
    },
    SET_AFF_ALLSALES(state, affallsales) {
      state.affallsales = affallsales;
    },
    SET_PPLEXIST(state, pplemailexist){
      state.pplemailexist = pplemailexist;
    },

    SET_ISAFFACTIVE(state, isaffActive){
      state.isaffActive = isaffActive;
    },
    SET_WDISMISSALERTE(state, dismissCountDown) {
      state.dismissCountDown = dismissCountDown;
    },
    SET_WMESSAGE(state, wmessage) {
      state.wmessage = wmessage;
    },
    SET_SIGNFORMSHOW(state, signupformshow){
      state.signupformshow = signupformshow;
    },
    SET_SIGNCODEFORMSHOW(state, signcodeformshow){
      state.signcodeformshow = signcodeformshow;
    },
    SET_FORGOTATTEMPS (state, forgotattemps){
      state.forgotattemps = forgotattemps;
    },
    SET_PASSCODEATTEMPS (state, passcodeattemps){
      state.passcodeattemps = passcodeattemps;
    },
    SET_SIGNGATTEMPS (state, signupattamps){
      state.signupattamps = signupattamps;
    },
    SET_EMAILCODESIGNUPEATTEMPS (state, emailsignupcodeattemps){
      state.emailsignupcodeattemps = emailsignupcodeattemps;
    },
    SET_PAYCODESTATE(state, paypalCodeState){
      state.paypalCodeState = paypalCodeState;
    },
    SET_PAYCODESENT(state, paypalCodeSent){
      state.paypalCodeSent = paypalCodeSent;
    },

    SET_PPLEMAIL(state, pplemail){
      state.pplemail = pplemail;
    },
  },

  actions: {

    async initialize({commit}){
      commit("SET_SPINNER", false);
      commit("SET_SOMETHING", false);
      commit("SET_SENDCODESTATE", [false,'','']);
      commit("SET_SIGNUPSTATE", [false,'','']);
      commit("SET_SIGNGATTEMPS", "0");
      commit("SET_PAYCODESTATE", [false,'','']);
      commit("SET_FORGOTATTEMPS", "0");
      commit("SET_PASSCODEATTEMPS", "0");
      commit("SET_EMAILCODESIGNUPEATTEMPS", "0");

    },

    async showheader({commit}){
      commit("SET_SHOW_HEADER", true);
    },

    async getcryptoaddr({ commit}){
      commit("SET_SOMETHING", true);
      const addr = await UserService.getcryptoaddr()
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                      var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302' || reStatus == '114'){
                          return ["","","",""];
                        } else {
                          return response.data.response.result;
                        }
                    } else {
                      return ["","","",""];
                    }
                    });
      commit("SET_ADDRESS", addr);
    },
    
    async getprice({ commit}){
      commit("SET_SOMETHING", true);
      const price = await UserService.getprice()
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                      var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302' || reStatus == '114'){
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                    } else {
                      return [];
                    }
                    });
      commit("SET_PRICE", price);
      const upradefiltered = await price.filter(obj => obj.product.includes('Upgrade'));
      const uprademapped = await upradefiltered.map(singleObj => {
            const rObj = {};
            rObj.text = singleObj.product+' - [ $'+singleObj.price+' ]';
            rObj.value = singleObj.id;
            return rObj;
            });
      commit("SET_UPGRADE", uprademapped);

      const mainmapped = await price.map(singleObj => {
            const rObj = {};
            rObj.text = singleObj.product+' - [ $'+singleObj.price+' ]';
            rObj.value = singleObj.product;
            return rObj;
            });
      commit("SET_MAIN", mainmapped);
    },

    async ordernow({commit},{product, referrer}){
          commit("SET_SOMETHING", true);
          const appUrl = await UserService.ordernow(product, referrer)
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                        var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302'){
                          return '';
                        } 
                        else if (reStatus == '111') {
                          return '';
                        }
                        else {
                          return response.data.response.result;
                        }
                    } else {
                      return '';
                    }
                    });
          
          window.location.replace(appUrl);               
    },
    
    async sendMessage({commit}, {name, email, subject, message}){
          commit("SET_SOMETHING", false);
          await UserService.sendMessage(name, email, subject, message)
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                        var reStatus = response.data.response.status;
                        if(reStatus == '200'){
                          return Promise.resolve(response);
                        }
                        else {
                          return Promise.reject(response);
                        }
                    } else {
                      return Promise.reject(response);
                      }
                    });       
                 
    },

    async btcOrder({commit}, {name, email, country, pack, thash}){
          commit("SET_SOMETHING", false);
          await UserService.btcOrder(name, email, country, pack, thash)
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                        var reStatus = response.data.response.status;
                        if(reStatus == '200'){
                          return Promise.resolve(response);
                        }
                        else {
                          return Promise.reject(response);
                        }
                    } else {
                      return Promise.reject(response);
                      }
                    });       
                 
    },

    async sendReview({commit}, {name, email, country, review}){
          commit("SET_SOMETHING", false);
          await UserService.sendReview(name, email, country, review)
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                        var reStatus = response.data.response.status;
                        if(reStatus == '200'){
                          return Promise.resolve(response);
                        }
                        else {
                          return Promise.reject(response);
                        }
                    } else {
                      return Promise.reject(response);
                      }
                    });            
    },

    walertcount({commit},{countdown}){
      commit("SET_WDISMISSALERTE", countdown);
    },

    async sendEmailPassCode({state, commit, dispatch},{cpath, email, router}){
        commit("SET_SENDCODESTATE", [false, '','']);
        commit("SET_SPINNER", true); 
        dispatch("setCookie", {cookieName: 'COD_ATPS_SPR'+cpath, commitName:"SET_PASSCODEATTEMPS", path:"/"+cpath, attemps:"0"});
        dispatch("getCookie", {cname: 'FRGT_ATPS_SPR'+cpath, commitVar:"SET_FORGOTATTEMPS"});
        if(parseInt(state.forgotattemps) < 3){
          await AuthService.sendEmailPassCode(email)
                      .then(response => {
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '116' || respStatus == '118'){
                            commit("SET_SENDCODESTATE", [true, response.data.response.message,'danger']);
                            commit("SET_SPINNER", false);
                            if (respStatus == '116') {
                              dispatch("setCookie", {cookieName: 'FRGT_ATPS_SPR'+cpath, commitName:"SET_FORGOTATTEMPS", path:"/"+cpath, attemps:state.forgotattemps});
                            }
                          }
                          else if (respStatus == '200'){
                            commit("SET_SENDCODESTATE", [true, response.data.response.result,'success']);
                            dispatch("setCookie", {cookieName: 'FRGT_ATPS_SPR'+cpath, commitName:"SET_FORGOTATTEMPS", path:"/"+cpath, attemps:state.forgotattemps});
                            commit("SET_VERIFEMAIL", email);
                            commit("SET_CODEFORMSHOW", false);
                            commit("SET_SPINNER", false);
                          } else {
                              commit("SET_SENDCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_SENDCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          commit("SET_SPINNER", false);
                        }
                        
                      });
        } else {
          commit("SET_SENDCODESTATE", [true,'Oops! Something went wrong, Try again later!', 'danger']);
          commit("SET_SPINNER", false);
        }
    },
    
    async verifyPassCode({state, commit, dispatch},{cpath, reason, code, email, router}){
      commit("SET_SENDCODESTATE", [false, '','']);
        commit("SET_SPINNER", true);
        dispatch("getCookie", {cname: 'COD_ATPS_SPR'+cpath, commitVar:"SET_PASSCODEATTEMPS"});
        if(parseInt(state.passcodeattemps) < 3){
          await AuthService.verifyPassCode(code, email)
                      .then(response => {
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          
                          else if(respStatus == '118'){
                            commit("SET_SENDCODESTATE", [true, response.data.response.message,'danger']);
                            dispatch("setCookie", {cookieName: 'COD_ATPS_SPR'+cpath, commitName:"SET_PASSCODEATTEMPS", path:"/"+cpath, attemps:state.passcodeattemps});
                            commit("SET_SPINNER", false);
                          }
                          else if (respStatus == '200'){
                            commit("SET_SENDCODESTATE", [true, response.data.response.result,'success']);
                            commit("SET_VERIFCODE", code);
                            commit("SET_SPINNER", false);
                            if(reason == 'passreset'){
                              router.push('/PasswordReset').catch(()=>{});
                            }else{
                              router.push('/login').catch(()=>{});
                            }
                            
                          } else {
                              commit("SET_SENDCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_SENDCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          commit("SET_SPINNER", false);
                        }
                        
                      });
        } else {
          commit("SET_SENDCODESTATE", [true,'Oops! Something went wrong, Try again later!', 'danger']);
          commit("SET_SPINNER", false);
        }
    },

    async resetPass({state, commit, dispatch},{password, router}){
      commit("SET_RESETPASSSTATE", [false, '','']);
        commit("SET_SPINNER", true);
        if (state.verifiedCode !=='' && state.verifiedEmail !== ''){
          await AuthService.resetPass(state.verifiedCode, state.verifiedEmail, password)
                .then(response => {
                  if (response.data!=='' && response.data.constructor === Object){
                    var respStatus = response.data.response.status;
                    if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                      dispatch("auth/logout");
                      router.push('/login').catch(()=>{});
                      commit("SET_SPINNER", false);
                    } 
                    
                    else if(respStatus == '118'){
                      commit("SET_RESETPASSSTATE", [true, response.data.response.message,'danger']);
                      commit("SET_SPINNER", false);
                    }
                    else if (respStatus == '200'){
                      commit("SET_RESETPASSSTATE", [true, response.data.response.result,'success']);
                      commit("SET_SPINNER", false);
                      router.push('/login').catch(()=>{});
                    } else {
                        commit("SET_RESETPASSSTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                        commit("SET_SPINNER", false);
                    }
                  } else{
                    commit("SET_RESETPASSSTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                    commit("SET_SPINNER", false);
                  }
                  
                });
        } else {
          router.push('/forgotPassword').catch(()=>{});
          commit("SET_SPINNER", false);
        }
    },

    initSignUpState({commit}){
      commit("SET_SIGNUPSTATE", [false, '','']);
    },

    async SignUp({state, commit, dispatch},{fullname,motivation,email,password,router}){
        commit("SET_SIGNUPSTATE", [false, '','']);
        commit("SET_SPINNER", true);
        dispatch("setCookie", {cookieName: 'SGN_COD_ATPS_SPR', commitName:"SET_EMAILCODESIGNUPEATTEMPS", path:"/Signup", attemps:"0"});
        dispatch("getCookie", {cname: 'SGN_ATPS_SPR', commitVar:"SET_SIGNGATTEMPS"});
        if( parseInt(state.signupattamps) < 6){
          await AuthService.SignUp(fullname,motivation,email, password)
                      .then(response => {
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '116' || respStatus == '118'){
                            commit("SET_SIGNUPSTATE", [true, response.data.response.message,'danger']);
                            commit("SET_SPINNER", false);
                            if (respStatus == '116') {
                              dispatch("setCookie", {cookieName: 'SGN_ATPS_SPR', commitName:"SET_SIGNGATTEMPS", path:"/Signup", attemps:state.signupattamps});
                            }
                          }
                          else if (respStatus == '200'){
                            commit("SET_SIGNUPSTATE", [true, response.data.response.result,'success']);
                            dispatch("setCookie", {cookieName: 'SGN_ATPS_SPR', commitName:"SET_SIGNGATTEMPS", path:"/Signup", attemps:state.signupattamps});
                            commit("SET_VERIFEMAIL", email);
                            commit("SET_SIGNFORMSHOW", false);
                            commit("SET_SIGNCODEFORMSHOW", true);
                            commit("SET_SPINNER", false);
                          } else {
                              commit("SET_SIGNUPSTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_SIGNUPSTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          commit("SET_SPINNER", false);
                        }
                        
                      });
        } else {
          commit("SET_SIGNUPSTATE", [true,'Oops! Something went wrong, Try again later!', 'danger']);
          commit("SET_SPINNER", false);
        }
    },

    async verifyEmailSignupCode({state, commit, dispatch},{code, email, router}){
      commit("SET_SIGNUPSTATE", [false, '','']);
        commit("SET_SPINNER", true);
        dispatch("getCookie", {cname: 'SGN_COD_ATPS_SPR', commitVar:"SET_EMAILCODESIGNUPEATTEMPS"});
        if(parseInt(state.emailsignupcodeattemps) < 3){
          await AuthService.verifyEmailSignupCode(code, email)
                      .then(response => {
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          
                          else if(respStatus == '118'){
                            commit("SET_SIGNUPSTATE", [true, response.data.response.message,'danger']);
                            dispatch("setCookie", {cookieName: 'SGN_COD_ATPS_SPR', commitName:"SET_EMAILCODESIGNUPEATTEMPS", path:"/Signup", attemps:state.emailsignupcodeattemps}); 
                            commit("SET_SPINNER", false);
                          }
                          else if (respStatus == '200'){
                            commit("SET_SIGNUPSTATE", [true, response.data.response.result,'success']);
                            commit("SET_SIGNCODEFORMSHOW", false);
                            commit("SET_SPINNER", false);
                          } else {
                              commit("SET_SIGNUPSTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_SIGNUPSTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          commit("SET_SPINNER", false);
                        }
                        
                      });
        } else {
          commit("SET_SIGNUPSTATE", [true,'Oops! Something went wrong, Try again later!', 'danger']);
          commit("SET_SPINNER", false);
        }
    },

    setCookie({commit, dispatch},{cookieName,commitName,path,attemps}) {
      //set expire time
      const d = new Date();
      d.setTime(d.getTime() + (15 * 60 * 1000));
      let expires = ";expires="+d.toUTCString();
     
      if(attemps == "0"){
        document.cookie = cookieName + "=1;" + expires + ";path=" + path + ";SameSite=None";
        commit(commitName, "1");
      } else {
        const newattemps = parseInt(attemps) + 1;
        document.cookie = cookieName + "=" + newattemps + expires + ";path=" + path + ";SameSite=None";
        commit(commitName, newattemps);
        dispatch("getCookie", {cname: cookieName, commitVar: commitName});
      }
    
    
    },

    getCookie({commit},{cname, commitVar}) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == '') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          const fc = c.substring(name.length, c.length);
          commit(commitVar, fc);
          return;
        } 
      } 
      return;
    },

    async getaffRecords({ commit, dispatch},{router}){
      commit("SET_SPINNER", true);
      const myJson = await UserService.getaffRecords()
                    .then(response => {
                      if (response.data!=='' && response.data.constructor === Object){
                        var resStatus = response.data.response.status;
                        if(resStatus == '300' || resStatus == '301' || resStatus == '302'|| resStatus == '108' || resStatus == '109'){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                        
                      }
                      return [];
                      
                    });
      if ('records' in myJson){
        //map items for tableview:
        const  topFiveJson = await myJson.topfive.map(singleObj => {
            const rObj = {};
            rObj.affid = singleObj.affiliate;
            rObj.rewards = singleObj.rewards;
            return rObj;
            });

        const mappedJson = await myJson.records.map(singleObj => {
            const rObj = {};
            rObj.orderid = singleObj.orderid;
            rObj.pack = singleObj.pack;
            rObj.price = singleObj.price;
            rObj.rewarded = singleObj.rewarded; 
            rObj.status = singleObj.status;
            rObj.date = singleObj.date;
            return rObj;
            });

        const uId = myJson.userId;
        var signedup = myJson.signedup;
        var isaffactive = myJson.isaffactive;
        var affId = myJson.affId;
        var balance = myJson.balance;
        var pplemailexist = myJson.pplemailexist;
        
        var thismonth = myJson.thismonth;
        var alltime = myJson.alltime;
        var affallsales = myJson.allsales;
       
        commit("SET_CURRENT_UID", uId);
        commit("SET_TOPFIVE", topFiveJson);
        commit("SET_AFFRECORDS", mappedJson);

        commit("SET_SIGNEDUP", signedup);
        commit("SET_AFFID", affId);
        commit("SET_ISAFFACTIVE", isaffactive);
        commit("SET_PPLEXIST", pplemailexist);
        commit("SET_AFF_BALANCE", balance);
        commit("SET_AFF_THISM", thismonth);
        commit("SET_AFF_ALLT", alltime);
        commit("SET_AFF_ALLSALES", affallsales);
        
        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }
    },

    async getTopRecords({ commit, dispatch},{router}){
      commit("SET_SPINNER", true);
      const myJson = await UserService.getTopRecords()
                    .then(response => {
                      if (response.data!=='' && response.data.constructor === Object){
                        var resStatus = response.data.response.status;
                        if(resStatus == '300' || resStatus == '301' || resStatus == '302' ){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                        
                      }
                      return [];
                      
                    });
      if ('topfive' in myJson){
        //map items for tableview:
        const  topFiveJson = await myJson.topfive.map(singleObj => {
            const rObj = {};
            rObj.affid = singleObj.affiliate;
            rObj.rewards = singleObj.rewards;
            return rObj;
            });

        
        commit("SET_TOPFIVE", topFiveJson);
        
        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }
    },

    async getReviews({ commit, dispatch},{router}){
      commit("SET_SPINNER", true);
      const myJson = await UserService.getReviews()
                    .then(response => {
                      if (response.data!=='' && response.data.constructor === Object){
                        var resStatus = response.data.response.status;
                        if(resStatus == '300' || resStatus == '301' || resStatus == '302' ){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                        
                      }
                      return [];
                    });

      if ('reviews' in myJson){
        const  reviewsJson = await myJson.reviews.map(singleObj => {
            const rObj = {};
            rObj.num = singleObj.num;
            rObj.name = singleObj.name;
            rObj.country = singleObj.country;
            rObj.review = singleObj.review;
            rObj.date = singleObj.date;
            return rObj;
            });
        commit("SET_REVIEWS", reviewsJson); 
        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }
    },

    async codePayEmail({commit, dispatch},{router}){
        commit("SET_PAYCODESTATE", [false, '','']);
        commit("SET_SPINNER", true);
        await UserService.codePayEmail()
                      .then(response => {
                    
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '118'){
                            commit("SET_PAYCODESTATE", [true, response.data.response.message,'danger']);
                            commit("SET_SPINNER", false);
                          }
                          else if (respStatus == '200'){
                            commit("SET_PAYCODESENT", true);
                            commit("SET_PAYCODESTATE", [true, response.data.response.result,'success']);
                            commit("SET_SPINNER", false);
                          } else {
                              commit("SET_PAYCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_PAYCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          commit("SET_SPINNER", false);
                        }
                        
                      });
      
    },
    


    async changePayEmail({commit, dispatch},{payemail, code, router}){
        commit("SET_PAYCODESTATE", [false, '','']);
        commit("SET_SPINNER", true);
        await UserService.changePayEmail(payemail, code)
                      .then(response => {
                      
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '118'){
                            commit("SET_PAYCODESTATE", [true, response.data.response.message,'danger']);
                            commit("SET_SPINNER", false);
                          }
                          else if (respStatus == '200'){
                            commit("SET_PAYCODESENT", false);
                            commit("SET_PAYCODESTATE", [true, response.data.response.result,'success']);
                            dispatch("getpplEmail", {router: this.$router});
                          } else {
                              commit("SET_PAYCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_PAYCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          commit("SET_SPINNER", false);
                        }
                        
                      });
      
    },

    async getpplEmail({commit, dispatch},{router}){
        commit("SET_SPINNER", true);
        const pplemail = await UserService.getpplEmail()
                      .then(response => {
                  
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '118'){
                            commit("SET_PAYCODESTATE", [true, response.data.response.message,'danger']);
                            commit("SET_SPINNER", false);
                            return ['',false];
                          }
                          else if (respStatus == '200'){
                            
                            commit("SET_SPINNER", false);
                            return response.data.response.result;
                          } else {
                             
                            commit("SET_SPINNER", false);
                             return ['',false];
                          }
                        } else{
                          commit("SET_SPINNER", false);
                          return ['',false];
                        }
                        
                      });

        commit("SET_PPLEMAIL", pplemail);
      
    },

    async withdraw({commit, dispatch},{router, affid}){
        commit("SET_SPINNER", true);
        await UserService.withdraw(affid)
                      .then(response => {
                      
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '120'){//withdraw error
                            commit("SET_SPINNER", false);
                            commit("SET_WMESSAGE", ['danger', response.data.response.message]);
                            commit("SET_WDISMISSALERTE", 10);
                              
                          }
                          else {
                            dispatch("getaffRecords", {router: this.$router});
                            commit("SET_WMESSAGE", ['success', response.data.response.result]);
                            commit("SET_WDISMISSALERTE", 10);
                          }
                        } else{
                          commit("SET_SPINNER", false);
                        }
                        
                      });
    },
   
  },
  
  getters: {
    getPrice(state){
      return state.price;
    },
    getAddress(state){
      return state.address;
    },
    getShowHeader(state){
      return state.showHeader;
    },

    getUpgrade(state){
      return state.upgrade;
    },
    getMainprice(state){
      return state.mainprice;
    },
    getSpinner(state) {
      return state.showSpinner;
    },
    getSendCodeState(state){
      return state.sendCodeState;
    },
    getSignUpeState(state){
      return state.signUpState;
    },
    getResetPassState(state){
      return state.resetPassState;
    },
    getReviews(state){
      return state.reviews;
    },

    //affiliate program
    getAffRecords(state){
      return state.affrecords;
    },
    getTopFive(state){
      return state.afftopfive;
    },

    getaffsignedup(state){
      return state.affsignedup;
    },
    getaffId(state){
      return state.affId;
    },
    getaffBalance(state){
      return state.affBalance;
    },
   
    getaffthismonth(state){
      return state.affthismonth;
    },
    getaffalltime(state){
      return state.affalltime;
    },

    getaffallsales(state){
      return state.affallsales;
    },

    getdismissCountDown(state){
      return state.dismissCountDown;
    },

    getpplexist(state){
      return state.pplemailexist;
    },

    getisaffActive(state){
      return state.isaffActive;
    },

    getWMessage(state){
      return state.wmessage;
    },
    getpaypalCodeState(state){
      return state.paypalCodeState;
    },

    getpaypalCodeSent(state){
      return state.paypalCodeSent;
    },
    getpplemail(state){
      return state.pplemail;
    },

  },

  modules: {
    auth
  }
  
});