<template>
	<b-container fluid class="px-1">    
	    <b-row class="justify-content-center mx-0 p-4">
	      <h1 style="text-align: center;">Silverdor Support</h1>
	    </b-row>

      <b-row class="justify-content-center mx-0 mb-4">
        <b-col xs="12" md="8" class="p-2">
        <b-alert v-if="getAddress[3].length>0" variant="info"  show dismissible>Important! {{getAddress[3]}}</b-alert>
        <p>
          <b>Do you Want to Join our Affiliate Program?</b> 
          <br>You get a 15% commission from each purchase of a Silverdor Package through your affiliate link, it's calculated based on the package price (e.g: Medium Package commission $39.95 x 15% = $6.00). <br> We will increase the commission rate to 20% after reaching 50 cleared sales, then 25% when you reach 200 cleared sales. 
          <br>(Note: We provide special partnership commission rates when your results are outstanding, 1000+ sales).<br>
          Join our Affiliate Program from: <a href="/Signup">SIGN UP!</a><br><br>

          <b>Didn’t receive Silverdor yet?</b><br>
          If you didn’t receive your product details and the full tutorial and docs needed yet, please check all tabs in your email inbox including spam tab, still not find it contact us from here.<br><br>

          <b>Do you Want to upgrade?</b><br>
          If you want to upgrade please click: <a href="/upgrade">UPGRADE ME!</a>
        </p>
      </b-col>
    </b-row> 

      <b-row class="justify-content-center mx-0">
      <b-col xs="12" md="8" class="p-2">
        <b-alert v-if="sendingstatus" variant="info"  show>{{sendingstatus}}</b-alert> 
        <b-form v-show="formshow" @submit.prevent="onSubmit">
          <b-form-group
            id="input-group-1"
            label="Full name (required):"
            label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="name"
              type="text"
              placeholder="Enter your name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Email address (required):"
            label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="email"
              type="email"
              placeholder="Enter your email"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Subject (required):"
            label-for="input-3">
            <b-form-input
              id="input-3"
              v-model="subject"
              type="text"
              placeholder="Enter the subject"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Message (required):"
            label-for="textarea"
            class="mb-4"
          >
            <b-form-textarea
              id="textarea"
              :state="textValidation"
              v-model="message"
              @input="textControl"
              placeholder="Enter at least 10 characters"
              class="mb-4"
              required
              rows="7"
            ></b-form-textarea>
          </b-form-group>
          <vue-recaptcha 
          class="my-4" 
          sitekey="6LdL0p4UAAAAADuEm8alM1PBd0VljyeuuNenRQKm"
          :loadRecaptchaScript="true"
          @verify="onVerify"
          @expired="onExpired"
          >
          </vue-recaptcha>
          <b-button 
          type="submit" 
          variant="primary"
          :disabled="loading"
          >
          <span v-show="loading" class="spinner-border spinner-border-sm mr-1"></span>
          <span>Send Message</span>
          </b-button>
          
          <b-alert class="my-4" v-if="sendingerror" variant="danger"  show>{{sendingerror}}</b-alert> 
        </b-form>
      </b-col>
      </b-row>

	</b-container> 
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { mapGetters } from "vuex";
export default {
 
  data() {
    return {
      name:"",
      email:"",
      subject:"",
      message:"",
      sendingstatus:'',
      textValidation:false,
      sendingerror:'',
      formshow: true,
      loading:false,
      captchaverify:false,
      captchaexpire:false
    };
  },
  mounted() {
      this.$store.dispatch("getcryptoaddr");
  },
  methods:{
    textControl(){
      if (this.message.length > 9 && this.message.length < 700){
        this.textValidation=true;
      }else{
        this.textValidation=false;
      }
    },
    onVerify: function (response) {
      this.sendingerror ='';
      if (response !== '') {
        this.captchaverify = true;
        this.captchaexpire = false;
      }
    },

    onExpired: function () {
      this.captchaexpire = true;
    },
     onSubmit(){
      this.sendingerror ='';
      if(this.captchaverify == true && this.captchaexpire == false){
        this.loading = true;
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            this.loading = false;
            return;
          }

          if (this.name && this.email && this.subject && this.textValidation) {
            this.$store.dispatch("sendMessage", {name:this.name, email:this.email, subject:this.subject, message:this.message}).then(
              () => {
                this.loading = false;
                this.formshow = false;
                this.sendingstatus = 'Your message was sent! Our support team will be in touch with you ASAP, Thank you!';
              },
              () => {
                this.loading = false;
                this.sendingerror = 'Error! something went wrong, please try again later!';
              }
            );
          }else{
            this.loading = false;
            this.sendingerror = 'Error! Missed or Invalid Entries!';
          }
        });
      }else{
        this.sendingerror = 'Error! Invalid or expired Captcha!';
      }
     }
  },

  components: { 
    "vue-recaptcha": VueRecaptcha,
  },
  computed: {
    ...mapGetters(["getAddress"]),
  }

};
</script>
<style scoped>


</style>