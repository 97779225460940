<template>
  <b-container fluid>
      <b-row class=" p-1" style="background-color:#2f2f30;">
        <b-button-group class="ml-auto">
        <b-button
                size="sm"
                variant="link"
                class=" text-secondary"
                @click.prevent="toDashboard"
                >
                <b-icon icon="pie-chart" variant="primary"  font-scale="1.2" aria-hidden="true"></b-icon>
              Dashboard</b-button>
        <b-button
                size="sm"
                variant="link"
                class=" text-secondary"
                @click.prevent="toTutorial"
                >
                <b-icon icon="book" variant="info"  font-scale="1.2" aria-hidden="true"></b-icon>
              Tutorial</b-button>
        <b-button
                size="sm"
                variant="link"
                class=" text-secondary"
                @click.prevent="toSettings"
                >
                <b-icon icon="gear" variant="warning"  font-scale="1.2" aria-hidden="true"></b-icon>
              Settings</b-button>
        
        <b-button
                size="sm"
                variant="link"
                class=" text-secondary"
                @click.prevent="logOut"
                >
                <b-icon icon="box-arrow-right" variant="danger"  font-scale="1.2" aria-hidden="true"></b-icon>
              Logout</b-button>
        </b-button-group>
      </b-row>
      <b-row class="" style="background-color:#2f2f77;">
        <div class="text-secondary"> Silverdor Affiliate Program <span class="text-warning">&#8250;</span> {{$route.name}}</div>
      </b-row>
      <b-row class="justify-content-center">
       
        <b-col v-if="getpplemail[1]" xs="12" md="4" class="p-4">
          <h4>Affiliate Paypal email</h4>
          <p v-if="getpplemail[0]">Current address: {{ getpplemail[0] }}</p>
          <b-alert v-if="getpaypalCodeState[0]" :variant="getpaypalCodeState[2]"  show dismissible>{{ getpaypalCodeState[1] }}</b-alert>
          <b-alert v-if="ptypeerr" variant="danger"  show dismissible>{{ ptypeerr }}</b-alert>
          <button class="submit_btn bg-primary" @click.prevent="sendCode" v-show="!getpaypalCodeSent">Add/Change Paypal Email</button>
          
          <form v-show="getpaypalCodeSent" @submit.prevent="onSubmitPayEmail" data-vv-scope="payform">
            <!-- INPUTS -->
            <div class="input-group">
            <div>
              <p>Enter Paypal Email: (Your money will be sent to this email) </p>
              <input
              @input="preseterr" 
              v-validate="'required|email'"
              v-model = "payemail"
              name="paypal_email" 
              type="email" 
              placeholder="Paypal-Email" 
              autocomplete="off">
            </div>
            </div>  
            <div class="input-group mt-3">
              <div class="mr-2">
                <p>Enter code sent to your email: </p>
                <input
                @input="preseterr"
                v-validate="'required'"
                v-model = "code"
                name="code" 
                type="text" 
                placeholder="Code"
                autocomplete="off">
              </div> 
            </div>

            <!-- ERRORS -->
            <div class="alert alert-danger" v-show="errors.any('payform')">
              <div  v-if="errors.has('payform.code')">
                {{ errors.first('payform.code') }}
              </div>
              <div  v-if="errors.has('payform.paypal_email')">
                {{ errors.first('payform.paypal_email') }}
              </div>
            </div>
            <button class="submit_btn bg-primary" type="submit" :disabled="errors.any('payform')">Submit Paypal Email</button>

          </form>

        </b-col>
      </b-row>
  </b-container>
</template>

<script>
  import { mapGetters } from "vuex";
export default {
 
  data() {
    return {
      payemail:'',
      newemail:'',
      ptypeerr:'',
      code:'',
    };
  },

  mounted(){
    this.$store.dispatch("initialize");
    this.$store.dispatch("getpplEmail", {router: this.$router});
  },

  computed: {
    ...mapGetters(["getpaypalCodeState","getpaypalCodeSent","getpplemail", "getpplexist"]),
  },

  methods: {

    sendCode(){
      this.codesent = true;
      this.$store.dispatch("codePayEmail", {router: this.$router});
    },

    onSubmitPayEmail () {
      this.$validator.validateAll('payform').then((result) => {
        if (result) {
          this.$store.dispatch("changePayEmail", {payemail: this.payemail, code: this.code, router: this.$router});  
        } else {
          this.ptypeerr = 'Please fix these errors!';
        }
        
      });
      
    },

    preseterr() {
      this.ptypeerr = '';
    },

    toSettings() {
      if(this.$route.path!=="/Settings"){
        this.$router.push('/Settings');
      }
    },

    toDashboard() {
      this.$router.push('/Affiliate-Dashboard');
    },

    toTutorial() {
        this.$router.push('/Tutorial');
    },

    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }

};

</script>

<style scoped>
  .submit_btn {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    margin-top: 16px;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    }
</style>