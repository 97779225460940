//(authentication module)

import AuthService from '../services/auth.service';

const sidorblueSky = JSON.parse(localStorage.getItem('sidorblueSky'));
const initialState = sidorblueSky
  ? { status: { loggedIn: true }, sidorblueSky }
  : { status: { loggedIn: false }, sidorblueSky: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, sidorblueSky) {
      return AuthService.login(sidorblueSky).then(
        response => {
          if (response.response.status == '200'){
            commit('loginSuccess', response);
            return Promise.resolve(response);
          } else {
            commit('loginFailure');
            return Promise.reject(response);

          }
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    }
  },
  mutations: {
    loginSuccess(state, sidorblueSky) {
      state.status.loggedIn = true;
      state.sidorblueSky = sidorblueSky;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.sidorblueSky = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.sidorblueSky = null;
    }
  }
};