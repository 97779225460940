<template>
 <b-container>
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" lg="4" class="p-3">
          <b-card class="bg-dark text-center text-light" title="Reset Password">
            <b-card-text>
              Enter a secure new password and confirm it, then click save password.
            </b-card-text>


            <b-alert v-if="getResetPassState[0]" :variant="getResetPassState[2]"  show>{{getResetPassState[1]}}</b-alert>
            <b-alert v-if="typeerr" variant="danger"  show dismissible>{{ typeerr }}</b-alert>


            <b-form data-vv-scope="resetform">
              <b-form-group>
                <b-form-input
                @input="reseterr"
                size="sm" 
                v-validate="'required|min:10|max:16'"
                v-model = "newpassword"
                name="new_password" 
                type="password" 
                placeholder="Enter New Password" 
                ref="newpassword"
                data-vv-as="New Password" 
                autocomplete="off"
                />
               
              </b-form-group>
              <b-form-group>
                <b-form-input
                @input="reseterr"
                size="sm" 
                v-validate="'required|confirmed:newpassword'"
                v-model = "confirmpassword"
                name="password_confirmation" 
                type="password" 
                placeholder="Re-enter New Password" 
                data-vv-as="Password Confirmation" 
                autocomplete="off"
                />
              </b-form-group>




              <!-- ERRORS -->
              <div class="alert alert-danger" v-show="errors.any('resetform')">
                <div class="fontsize" v-if="errors.has('resetform.new_password')">
                  &#10004; {{ errors.first('resetform.new_password') }}
                </div>
                <div class="fontsize" v-if="errors.has('resetform.password_confirmation')">
                  &#10004; {{ errors.first('resetform.password_confirmation') }}
                </div>
              </div>



              <b-form-group>
                <b-button 
                variant="primary" 
                size="sm" 
                class="btn-block" 
                :disabled="loading"
                @click.prevent="resetPass"
                >
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>Save Password</span>
                </b-button>
                <b-button 
                size='sm'
                variant="link"
                ><a href="https://silverdor.com/Support" target="_blank">Contact us</a></b-button>
              </b-form-group>



             
            </b-form>
          </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      newpassword:'',
      confirmpassword:'',
      typeerr:'',
      loading: false

    };
  },
  mounted(){
    this.$store.dispatch("initialize");
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    ...mapGetters(["getResetPassState"]),
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    resetPass () {

      this.$validator.validateAll('resetform').then(
        (result) => {
        if (result) {
          this.$store.dispatch("resetPass", {password: this.newpassword, router: this.$router});  
        } else {
          this.typeerr = 'Please fix these errors!';
        }
        
      });
      
    },

    reseterr() {
      this.typeerr = '';
    },
  }







};

</script>

<style scoped>
.fontsize{
  font-size: 12px;
}
.profile-img-card {
  width: 75px;
  height: 75px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 25%;
  -webkit-border-radius: 25%;
  border-radius: 25%;
}
</style>