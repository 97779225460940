// (Data service)
//Now we define a service for accessing data

import axios from 'axios';
import authHeader from './auth-header';
const API_URL = 'https://silverdormanager.univerhome.com/api/fth-api/';

class UserService {

  	ordernow(item, referrer) {
      return axios.post(API_URL,  {
                          "name":"ordernow",
                          "param":{
                                  "product": item,
                                  "referrer": referrer
                          }
                      },
                      { headers: {
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    sendMessage(name, email, subject, message) {
          return axios.post(API_URL,  {
                              "name":"sendmessage",
                              "param":{
                                      "name": name,
                                      "email": email,
                                      "subject": subject,
                                      "message": message
                              }
                          },
                          { headers: {
                                'Content-Type': 'application/json' 
                              }
                          });
    }

    btcOrder(name, email, country, pack, thash) {
          return axios.post(API_URL,  {
                              "name":"btcorder",
                              "param":{
                                      "name": name,
                                      "email": email,
                                      "country": country,
                                      "pack": pack,
                                      "thash": thash
                              }
                          },
                          { headers: {
                                'Content-Type': 'application/json' 
                              }
                          });
    }

    sendReview(name, email, country, review) {
          return axios.post(API_URL,  {
                              "name":"sendreview",
                              "param":{
                                      "name": name,
                                      "email": email,
                                      "country": country,
                                      "review": review
                              }
                          },
                          { headers: {
                                'Content-Type': 'application/json' 
                              }
                          });
    }

    getprice() {
          return axios.post(API_URL,  {
                              "name":"getprice",
                              "param":{
                                      "price": 'price',           
                              }
                          },
                          { headers: {
                                'Content-Type': 'application/json' 
                              }
                          });
    }

    getcryptoaddr() {
          return axios.post(API_URL,  {
                              "name":"getcryptoaddr",
                              "param":{
                                      "address": 'address',           
                              }
                          },
                          { headers: {
                                'Content-Type': 'application/json' 
                              }
                          });
    }

    //Affiliate program
    getaffRecords() {
      return axios.post(API_URL,  {
                      "name":"getaffRecords",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    getTopRecords() {
      return axios.post(API_URL,  {
                      "name":"getTopRecords",
                      "param":{}
                      },
                      { headers: {
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    getReviews() {
      return axios.post(API_URL,  {
                      "name":"getReviews",
                      "param":{}
                      },
                      { headers: {
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    codePayEmail() {
      return axios.post(API_URL,  {
                      "name":"codePayEmail",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    getpplEmail() {
      return axios.post(API_URL,  {
                      "name":"getpplEmail",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    changePayEmail(payemail, code) {
      return axios.post(API_URL,  {
                      "name":"changePayEmail",
                      "param":{
                        "payemail": payemail,
                        "code": code,
                      }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    withdraw(affid) {
      return axios.post(API_URL,  {
                          "name":"withdraw",
                          "param":{
                            "affid": affid,
                          }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }
    
}

export default new UserService();