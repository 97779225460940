import Vue from "vue";
import VueRouter from "vue-router";
import Support from "../views/Support.vue";
import Terms from "../views/Terms.vue";
import Cookies from "../views/Cookies.vue";
import Privacypolicy from "../views/Privacypolicy.vue";
import Silverdor from "../views/Silverdor.vue";
import Successpayment from "../views/Successpayment.vue";
import Failedpayment from "../views/Failedpayment.vue";
import Reviews from "../views/Reviews.vue";
import Tutorial from "../views/Tutorial.vue";
import Cryptopay from "../views/Cryptopay.vue";
import Upgrade from "../views/Upgrade.vue";
import Download from "../views/Download.vue";
import AffDashboard from '../views/AffDashboard.vue';
import Login from '../views/Login.vue';
import Signup from '../views/Signup.vue';
import ForgotPass from '../views/LoginForget.vue';
import PassReset from '../views/LoginPassReset.vue';
import Settings from '../views/Settings.vue';
import verifyEmail from '../views/verifyEmail.vue';
import AffTutorial from '../views/AffTutorial.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Silverdor
  },
  {
    path: "/sldr-tutorial",
    name: "Tutorial",
    component: Tutorial
  },
  {
    path: "/download/393",
    name: "Download",
    component: Download
  },
  {
    path: "/upgrade",
    name: "Upgrade",
    component: Upgrade
  },
  {
    path: "/crypto-payment",
    name: "Cryptopay ",
    component: Cryptopay 
  },
  {
    path: "/Reviews",
    name: "Reviews",
    component: Reviews
  },
  {
    path: "/Support",
    name: "Support",
    component: Support
  },
  {
    path: "/support",
    component: Support
  },
  {
    path: "/Terms",  
    name: "Terms",
    component: Terms
  },
  {
    path: "/Cookies",
    name: "Cookies",
    component: Cookies
  },
  {
    path: "/Privacypolicy",
    name: "Privacypolicy",
    component: Privacypolicy
  },
  {
    path: "/Success-payment",
    name: "Successpayment",
    component: Successpayment
  },

  {
    path: "/Failed-payment",
    name: "Failedpayment",
    component: Failedpayment
  },
  //Affiliate routes
  {
    path: "/Affiliate-Dashboard",
    name: "Affiliate Dashboard",
    component: AffDashboard
  },

  {
    path: '/login',
    component: Login
  },
  {
    path: '/Signup',
    component: Signup
  },
  
  {
    path: '/forgotPassword',
    component: ForgotPass
  },

  {
    path: '/PasswordReset',
    component: PassReset
  },
  {
    path: '/verifyEmail',
    component: verifyEmail
  },
  {
    path: "/Settings",
    name: "Settings",
    component: Settings
  },

  {
    path: "/Tutorial",
    name: "AffTutorial",
    component: AffTutorial
  },
  
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const privatePages = ["/Affiliate-Dashboard", "/Settings", "/Tutorial"];
  const authRequired = privatePages.includes(to.path);
  const loggedIn = localStorage.getItem('sidorblueSky');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
