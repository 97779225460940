<template>
  <b-container fluid class="px-1">
    <b-row class="justify-content-center mt-4 mb-2" no-gutters> 
       <b-button 
            v-show="formshow"
            class="p-2" 
            size="lg"
            rounded
            id="scrol23" 
            variant="warning"
            @click.prevent="scrollto"
            > Submit a review! </b-button>
      </b-row>
  
    <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="8" class="p-2">
        <div v-for="review in getReviews" v-bind:key="review.num" style="margin: 10px; background:#F3F3F3;padding: 10px;border-radius: 10px;">{{review.name}}<br>
        {{review.country}}<br>
        {{review.date}}<br><br>

        {{review.review}}</div>

        
        </b-col>
    </b-row>

    <b-row class="justify-content-center mx-0 p-4">
        <h1 v-show="formshow" id="review-submit" style="text-align: center;">Submit a Review</h1>
      </b-row>

      <b-row class="justify-content-center mx-0">
      <b-col xs="12" md="8" class="p-2">
        <b-alert v-if="sendingstatus" variant="info"  show>{{sendingstatus}}</b-alert> 
        <b-form v-show="formshow" @submit.prevent="onSubmit">
          <b-form-group
            id="input-group-1"
            label="Full name (required):"
            label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="name"
              type="text"
              placeholder="Enter your name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Email address (required):"
            label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="email"
              type="email"
              placeholder="Enter your email"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Country (required):"
            label-for="input-3">
            <b-form-input
              id="input-3"
              v-model="country"
              type="text"
              placeholder="Enter the country"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Review (required):"
            label-for="textarea"
            class="mb-4"
          >
            <b-form-textarea
              id="textarea"
              :state="textValidation"
              @input="textControl"
              v-model="review"
              placeholder="Enter at least 10 characters"
              class="mb-4"
              required
              rows="7"
            ></b-form-textarea>
          </b-form-group>
          <vue-recaptcha 
          class="my-4" 
          sitekey="6LdL0p4UAAAAADuEm8alM1PBd0VljyeuuNenRQKm"
          :loadRecaptchaScript="true"
          @verify="onVerify"
          @expired="onExpired"
          >
          </vue-recaptcha>
          <b-button 
          type="submit" 
          variant="primary"
          :disabled="loading"
          >
          <span v-show="loading" class="spinner-border spinner-border-sm mr-1"></span>
          <span>Submit Review</span>
          </b-button>
          
          <b-alert class="my-4" v-if="sendingerror" variant="danger"  show>{{sendingerror}}</b-alert> 
        </b-form>
      </b-col>
      </b-row>


  </b-container>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha';
  import { mapGetters } from "vuex";
export default {
 
  data() {
    return {
      name:"",
      email:"",
      country:"",
      review:"",
      textValidation:false,
      sendingstatus:'',
      sendingerror:'',
      formshow: true,
      loading:false,
      captchaverify:false,
      captchaexpire:false
    }   
  },
  computed: {
    ...mapGetters(["getReviews"])
  },
  mounted(){
    this.$store.dispatch("getReviews", {router: this.$router});
  },
  methods:{
    textControl(){
      if (this.review.length > 9 && this.review.length < 500){
        this.textValidation=true;
      }else{
        this.textValidation=false;
      }
    },
    scrollto(){
      document.getElementById('review-submit').scrollIntoView();
    },
    onVerify: function (response) {
      this.sendingerror ='';
      if (response !== '') {
        this.captchaverify = true;
        this.captchaexpire = false;
      }
    },

    onExpired: function () {
      this.captchaexpire = true;
    },
     onSubmit(){
      this.sendingerror ='';
      if(this.captchaverify == true && this.captchaexpire == false){
        this.loading = true;
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            this.loading = false;
            return;
          }

          if (this.name && this.email && this.country && this.textValidation) {
            this.$store.dispatch("sendReview", {name:this.name, email:this.email, country:this.country, review:this.review}).then(
              () => {
                this.loading = false;
                this.formshow = false;
                this.sendingstatus = 'Review submitted, Thank you!';
              },
              () => {
                this.loading = false;
                this.sendingerror = 'Oops! something went wrong, please try again later!';
              }
            );
          }else{
            this.loading = false;
            this.sendingerror = 'Error! Missed or Invalid Entries!';
          }
        });
      }else{
        this.sendingerror = 'Invalid or expired Captcha!';
      }
     }
  },

  components: { 
    "vue-recaptcha": VueRecaptcha,
  }

  
};
</script>
<style scoped>
 .border-left{
  background: #482b69;
  border: none;
 }
 .sectitle{
  color: #ffba00;
  text-align: center;
 }
 .whitetitle{
  color: #fff;
  text-align: center;
 }
 .price-style{
  text-align: center;
  color: #25964d;
  font-family: 'almendra display',Sans-serif;
  font-weight: 700; 
}
.bg-trans{
  background: transparent;
  border: none;
  padding: 10px 20px;
}
.bg-trans-p6{
  background: transparent;
  border: none;
  padding: 5px 20px;
}
.txt-grey{
  color: white;
}
.txt-grey1{
  color: grey;
}
.iconpostion{
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.bg-orge{
  background-color: #ff9400;
}
.bg-blue1{
  background-color: #007aff;
}
.bg-puplea{
  background-color: #6f42c1;
}
.bg-pupleb{
  background-color: #c7a2fc;
}
.bg-greenb{
background-color: #fff;
}
.bg-img{
  background-color: transparent;
  background-image: linear-gradient(140deg,#1A1060 17%,#f2295b 100%);
}
@media not screen and (max-width: 767px) {
  .padd-x{
    padding-right: 10%;
    padding-left: 10%;
  }
}
</style>