<template>
 <b-container>
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" lg="4" class="p-3">
          <b-card class="bg-dark text-center text-light" title="Email Verification">
            <b-card-text v-show="formshow">
              Enter your email correctly then click send, you will receive the verification instructions, if you can not remember your email contact us.
            </b-card-text>
            <b-card-text v-show="!formshow">
              Enter the code sent to your email (it could take 5mins), check the spam folder too. If it's not received yet try to send it again or contact us.
            </b-card-text>
            <b-alert v-if="message[0]" :variant="message[2]"  show>{{message[1]}}</b-alert>
            <b-alert v-if="getSendCodeState[0]" :variant="getSendCodeState[2]"  show>{{getSendCodeState[1]}}</b-alert>
            <b-form data-vv-scope="sendform" v-show="formshow">
              <b-form-group>
                <b-form-input
                @input="reseterr"
                size="sm"
                placeholder="Email"
                v-model="email"
                v-validate="'required|email'"
                type="email"
                data-vv-as="email"
                name="email"
                /> 
              </b-form-group>


              <!-- ERRORS -->
              <div class="alert alert-danger" v-show="errors.any('sendform')">
                <div class="fontsize" v-if="errors.has('sendform.email')">
                  {{ errors.first('sendform.email') }}
                </div>
                <div class="fontsize" v-if="errors.has('sendform.password_confirmation')">
                  &#10004; {{ errors.first('sendform.password_confirmation') }}
                </div>
              </div>
             
              <b-form-group>
                <b-button 
                variant="primary" 
                size="sm" 
                class="btn-block" 
                :disabled="loading"
                @click.prevent="sendEmailPassCode"
                >
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>Send Email</span>
                </b-button>
              </b-form-group>
            </b-form>

            <b-form data-vv-scope="submitcode" v-show="!formshow">
              <b-form-group>
                <b-form-input
                @input="reseterr"
                size="sm"
                placeholder="Enter verification code"
                v-model="code"
                v-validate="'required'"
                type="text"
                data-vv-as="code"
                name="code"
                /> 
              </b-form-group>


              <!-- ERRORS -->
              <div class="alert alert-danger" v-show="errors.any('submitcode')">
                <div class="fontsize" v-if="errors.has('submitcode.code')">
                  &#10004; {{ errors.first('submitcode.code') }}
                </div>
              </div>
             
              <b-form-group>
                <b-button 
                variant="primary" 
                size="sm" 
                class="btn-block" 
                :disabled="submitloading"
                @click.prevent="verifyPassCode"
                >
                  <span v-show="submitloading" class="spinner-border spinner-border-sm"></span>
                  <span>Submit Code</span>
                </b-button>
              </b-form-group>
              
              </b-form>
              <b-button 
                size='sm'
                variant="link"
                class="btn-block"
                ><a href="https://silverdor.com/Support" target="_self">Contact us</a>
              </b-button>
              </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      //formshow:true,
      email: '',
      loading: false,
      submitloading: false,
      message: [false,'',''],
      code:''
    };
  },
  mounted(){
    this.$store.dispatch("initialize");
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    ...mapGetters(["getSendCodeState"]),

    formshow(){
        return this.$store.state.codeformshow;     
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    sendEmailPassCode() {
      this.loading = true;
      this.$validator.validateAll('sendform').then(

      (isValid) => {
        if (isValid) {
          this.loading = false;
          this.$store.dispatch('sendEmailPassCode', {cpath:"verifyEmail", email:this.email, router: this.$router})
          this.loading = false;     
        } 
        else {
          this.message = [true,'Please fix these errors!', 'danger'];
          this.loading = false;
        }
      }, 
      (error) => {
              this.loading = false;
              this.message = [true, error, 'danger'];
      });
    },

    verifyPassCode() {
      this.submitloading = true;
      this.$validator.validateAll('submitcode').then(

      (isValid) => {
        if (isValid) {
          this.submitloading = false;
          this.$store.dispatch('verifyPassCode', {cpath:"verifyEmail", reason: 'verifemail', code: this.code, email:this.email, router: this.$router})
          this.submitloading = false; 
        } else {
          this.message = [true, 'Please fix these errors!', 'danger'];
          this.submitloading = false;
        }
      }, 
      (error) => {
              this.submitloading = false;
              this.message = [true, error, 'danger'];
      });
    },

    reseterr() {
      this.message = [false,'',''];
    },
  
  },
};
</script>

<style scoped>
.fontsize{
  font-size: 12px;
}
.profile-img-card {
  width: 75px;
  height: 75px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 25%;
  -webkit-border-radius: 25%;
  border-radius: 25%;
}
</style>