<template>
	<b-container fluid class="px-1">
  <div style="display: none;">
      <iframe :src="wiframeSrc"/>
  </div>
  <div style="display: none;">
      <iframe :src="miframeSrc"/>
  </div>
	<b-row class="justify-content-center mx-0">
	      <h3 style="text-align: center;">Depending on your operating system<br> Click to dowload Silverdor</h3>
	</b-row> 
    <b-row class="justify-content-center mt-4 mb-4" no-gutters>
       <b-button
            class="p-4 dwd-btn"
            size="lg"
            rounded
            id="scrol23"
            variant="primary"
            :disabled="loading"
            @click="wdownload"
            > Windows</b-button>
    </b-row>
    <b-row class="justify-content-center mt-4 mb-4" no-gutters>
       <b-button
            class="p-4 dwd-btn"
            size="lg"
            rounded
            id="scrol23"
            variant="warning"
            :disabled="loading"
            @click="mdownload"
            > Mac OS</b-button>
    </b-row>
    <b-row class="justify-content-center mx-0">
	      <p style="text-align: center;">Note: If download failed try this options: <br>
	      1) Refresh the page and try again.<br>
	      2) Use another browser.<br>
	      3) Contact us for support.</p>
	</b-row> 
	</b-container> 
</template>

<script>

export default {
 
  data() {
    return {
    loading: false,
    wiframeSrc:'',
    miframeSrc:'',
    }   
  },
  mounted() {
    	
  },
  methods:{
  	wdownload(){
      this.wiframeSrc='https://silverdormanager.univerhome.com/uploads/app/Silverdor.zip';
      this.loading = true;
  	},
    mdownload(){
      this.miframeSrc='https://silverdormanager.univerhome.com/uploads/app/Silverdor.dmg';
      this.loading = true;
    }
  },
  components: {
  },
  computed: {
  }

};
</script>
<style scoped>
  .dwd-btn{
    width: 250px;
  }
</style>